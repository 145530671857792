<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <SplashScreen v-if="onloading"/>
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            Model List
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <input type="file" accept="application/xlsx"  class="fileinput bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"  @change="onChange5">
          <button @click="upload_file()"
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
          Upload
          </button>
          
        </div>
      </div>
    </div>
    <div>
      <b-card no-body>
        <b-tabs  card vertical>
          <b-tab lazy @click="getDetails('Mobile','Apple')"  active style="padding: 0px !important;height: 10px !important;" >
            <template #title>
              <strong>Mobile  </strong> <b-spinner variant="success" v-if="!data && active_tab == 'Mobile'" type="border" small></b-spinner>
            </template>
            <Loading v-if="!data"/>
            <b-card-text>
            <b-card no-body   style="padding: 0px !important; border: none; ">
              <b-tabs  align="center" style="padding: 0px !important; border: none !important;; ">
                <b-tab v-for="i in data" :key="i" :title="i.name" @click="getDetails2('Mobile',i.name)"><b-card-text>
                  <div class=" block w-full overflow-x-auto  " style="height: 400px;">
                    <table cellspacing="0" class="items-center w-full bg-transparent border-collapse ">
                      <thead>
                        <tr>
                          <th
                           
                          >
                          Model
                          </th>
                          <th
                           
                          >
                          Condition 1 Price
                          </th>
                          <th
                           
                          >
                          Condition 2 Price
                          </th>
                          <th
                           
                          >
                          Condition 3 Price
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in data2" :key="i" @click="getBuyback(i.id)">
                            <td >
                              {{ i.name }}
                            </td>
                            <td >
                              {{ i.a }}
                            </td>
                            <td >
                              {{ i.b }}
                            </td>
                            <td >
                              {{ i.c }}
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-text></b-tab>
              </b-tabs>
              
            </b-card>
          </b-card-text></b-tab>
          <b-tab lazy title="Laptop" @click="getDetails('Laptop','Acer')"  style="padding: 0px !important;height: 10px !important;" >
            <template #title>
              <strong>Laptop  </strong> <b-spinner variant="success" v-if="!data && active_tab == 'Laptop'" type="border" small></b-spinner>
            </template>
            <Loading v-if="!data"/>
            <b-card-text>
            <b-card no-body   style="padding: 0px !important; border: none; ">
              <b-tabs  align="center" style="padding: 0px !important; border: none !important;; ">
                <b-tab v-for="i in data" :key="i" :title="i.name" @click="getDetails2('Laptop',i.name)"><b-card-text>
                  <div class="block w-full overflow-x-auto" style="height: 400px;">
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr>
                          <th
                            
                          >
                          Model
                          </th>
                          <th
                            
                          >
                          Condition 1 Price
                          </th>
                          <th
                            
                          >
                          Condition 2 Price
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            
                          >
                          Condition 3 Price
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in data2" :key="i" @click="getBuyback(i.id)">
                            <td  >
                              {{ i.name }}
                            </td>
                            <td  >
                              {{ i.a }}
                            </td>
                            <td  >
                              {{ i.b }}
                            </td>
                            <td  >
                              {{ i.c }}
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-text></b-tab>
              </b-tabs>
              
            </b-card>
          </b-card-text></b-tab>
          <b-tab lazy title="Tablets" @click="getDetails('Tablets','Apple')"  style="padding: 0px !important;height: 10px !important;" >
            <template #title>
              <strong>Tablets  </strong> <b-spinner variant="success"  v-if="!data && active_tab == 'Tablets'" type="border" small></b-spinner>
            </template>
            <Loading v-if="!data"/>
            <b-card-text>
            <b-card no-body   style="padding: 0px !important; border: none; ">
              <b-tabs  align="center" style="padding: 0px !important; border: none !important;; ">
                <b-tab v-for="i in data" :key="i" :title="i.name" @click="getDetails2('Tablets',i.name)"><b-card-text>
                  <div class="block w-full overflow-x-auto" style="height: 400px;">
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr>
                          <th
                            
                          >
                          Model
                          </th>
                          <th
                           
                          >
                          Condition 1 Price
                          </th>
                          <th
                          
                          >
                          Condition 2 Price
                          </th>
                          <th
                           
                          >
                          Condition 3 Price
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in data2" :key="i" @click="getBuyback(i.id)">
                            <td  >
                              {{ i.name }}
                            </td>
                            <td  >
                              {{ i.a }}
                            </td>
                            <td  >
                              {{ i.b }}
                            </td>
                            <td  >
                              {{ i.c }}
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-text></b-tab>
              </b-tabs>
              
            </b-card>
          </b-card-text></b-tab>
          <b-tab lazy title="Smartwatches" @click="getDetails('Smartwatches','Apple')"  style="padding: 0px !important;height: 10px !important;" >
            <template #title>
              <strong>Smartwatches  </strong> <b-spinner variant="success"  v-if="!data && active_tab == 'Smartwatches'" type="border" small></b-spinner>
            </template>
            <Loading v-if="!data"/>
            <b-card-text>
            <b-card no-body   style="padding: 0px !important; border: none; ">
              <b-tabs  align="center" style="padding: 0px !important; border: none !important;; ">
                <b-tab v-for="i in data" :key="i" :title="i.name" @click="getDetails2('Smartwatches',i.name)"><b-card-text>
                  <div class="block w-full overflow-x-auto" style="height: 400px;">
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr>
                          <th
                           
                          >
                          Model
                          </th>
                          <th
                           
                          >
                          Condition 1 Price
                          </th>
                          <th
                           
                          >
                          Condition 2 Price
                          </th>
                          <th
                           
                          >
                          Condition 3 Price
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in data2" :key="i" @click="getBuyback(i.id)">
                            <td  >
                              {{ i.name }}
                            </td>
                            <td  >
                              {{ i.a }}
                            </td>
                            <td  >
                              {{ i.b }}
                            </td>
                            <td  >
                              {{ i.c }}
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-text></b-tab>
              </b-tabs>
              
            </b-card>
          </b-card-text></b-tab>
          <b-tab lazy title="Headphones & wireless audio"  @click="getDetails('Headphones & wireless audio', 'Apple')"  style="padding: 0px !important;height: 10px !important;" >
            <template #title>
              <strong>Headphones & wireless audio  </strong> <b-spinner variant="success"  v-if="!data && active_tab == 'Headphones & wireless audio'" type="border" small></b-spinner>
            </template>
            <Loading v-if="!data"/>
            <b-card-text>
            <b-card no-body   style="padding: 0px !important; border: none; ">
              <b-tabs  align="center" style="padding: 0px !important; border: none !important;; ">
                <b-tab v-for="i in data" :key="i" :title="i.name" @click="getDetails2('Headphones & wireless audio',i.name)"><b-card-text>
                  <div class="block w-full overflow-x-auto" style="height: 400px;">
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr>
                          <th
                           
                          >
                          Model
                          </th>
                          <th
                           
                          >
                          Condition 1 Price
                          </th>
                          <th
                            
                          >
                          Condition 2 Price
                          </th>
                          <th
                           
                          >
                          Condition 3 Price
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in data2" :key="i" @click="getBuyback(i.id)">
                            <td  >
                              {{ i.name }}
                            </td>
                            <td  >
                              {{ i.a }}
                            </td>
                            <td  >
                              {{ i.b }}
                            </td>
                            <td  >
                              {{ i.c }}
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-text></b-tab>
              </b-tabs>
              
            </b-card>
          </b-card-text></b-tab>
        </b-tabs>
      </b-card>
    </div>
    <div>
    <b-modal ref="modal-12" hide-footer no-close-on-backdrop no-close-on-esc title="Price List">
      <div class="d-block text-center">
        <h3>Uploaded Successfully</h3>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="toggleModal()">Done</b-button>
    </b-modal>
  </div>
  <div>
    <b-modal ref="modal-13" hide-footer no-close-on-backdrop no-close-on-esc title="Upload Failed">
      <div class="d-block text-center">
        <h3>{{error_msg}}</h3>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="toggleModal()">Done</b-button>
    </b-modal>
  </div>
  </div>
</template>
<script>
import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";
import SplashScreen from "../SplashScreen.vue";
import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";
import Loading from '../Loading.vue'
import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import api from '../../api'

export default {
  data() {
    return {
      file5: null,
      error_msg: '',
      id: this.$route.params.id,
      bootstrap,
      angular,
      sketch,
      react,
      vue,
      team1,
      team2,
      onloading:false,
      team3,
      team4,
      active_tab:null,
      data:null,
      data2:null,
      json_fields:{
        "Device":"name",
        "Grade A":"a",
        "Grade B":"b",
        "Grade C":"c"
        },
        json_data: []
    };
  },
  components: {
    TableDropdown,
    Loading,
    SplashScreen
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    // getBuyback: function (id) {
    //   this.$router.push({ path: '/admin/batch/'+ id, params: { 'id': id } })
    // },
    showimg: function (id) {
      this.$bvModal.show(id)
    },
    onChange5(e) {
        if (! e.target.files.length) return;
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
            let src = e.target.result;
            this.file5 = e.target.result;
  
        };
      },
      upload_file: function () {
        this.onloading = true
        var token_data = JSON.parse(localStorage.getItem('token_data'));
        const inputs = {
          
              'attachment': this.file5.replace('data:', '').replace(/^.+,/, ''),
              'file_type5':'data',
              'type':'xlsx',
              'user':this.$store.state.user.uid,
              'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
            
        }
        api.request(process.env.VUE_APP_Upload_Pricing, inputs).then((response) => {
          this.onloading = false
          const data = response.data
          if (data.errorcode) {
            this.errormessage = data.errormessage

          } else if (data.result  === true) {
            this.data = data.result
            this.$refs['modal-12'].show()

          } else if (data.result.error) {
            this.error_msg = data.result.error
            this.$refs['modal-13'].show()
          }

        }).catch((error) => {
          this.errormessage = error
        })
      },
    getDetails: function (id, brand) {
      this.active_tab = id
      this.data = null
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
        
            'cate': id,
            'country':this.$store.state.partner,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,

      }
      api.request(process.env.VUE_APP_Get_Pricing_Data, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result && typeof data.result === 'object') {
          this.data = data.result
          
          this.getDetails2(id, brand)
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    toggleModal() {
      this.$router.go()
    },
    getDetails2: function (cate,brand) {
      
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
      
            'cate':cate,
            'brand': brand,
            'country':this.$store.state.partner,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,

     
      }
      api.request(process.env.VUE_APP_Get_Pricing_Data2, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result && typeof data.result === 'object') {
          this.data2 = data.result
          for (let i = 0; i<= this.data2.length; i++) {
            console.log(i)
            this.json_data[i].append({
              'name': this.data2[i].name
            })
            console.log(this.json_data)
          }
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    }
  },
  mounted () {
      // this.getDetails('Mobile')
      // this.getDetails('Mobile','Apple')
  }
};
</script>

<style scoped>
ul.a {
  list-style-type: circle;
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  text-align:justify;
}

th, td {
      width:fit-content;
      text-align:center;
      border:1px solid rgb(218, 208, 208);
      padding:10px;
      font-size: 12px;
      
         
}
</style>