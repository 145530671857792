import Vue from 'vue'
import VueMeta from 'vue-meta'
import { BootstrapVue } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

import App from './App.vue'
import router from './router'
import store from './store'

import * as VueGoogleMaps from 'vue2-google-maps'

import VueApexCharts from 'vue-apexcharts'
import VueKinesis from 'vue-kinesis'
import {Swiper} from 'vue2-swiper'
import VueHorizontal from 'vue-horizontal';

import FileReader from 'vue-filereader'
import VueQRCodeComponent from 'vue-qrcode-component'
import OtpInput from "@bachdgvn/vue-otp-input";
import VueCountryDropdown from "vue-country-dropdown";
import JsonExcel from "vue-json-excel";
import VueExcelXlsx from "vue-excel-xlsx";
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueCountryDropdown);
Vue.use(VueExcelXlsx);
Vue.component("v-otp-input", OtpInput);
Vue.component('qr-code', VueQRCodeComponent)
Vue.component(FileReader.name, FileReader)
Vue.use(VueHorizontal)
Vue.use(Swiper)
Vue.use(VueKinesis)
Vue.use(BootstrapVue)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
    libraries: 'places'
  }
})
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  beforeCreate () { this.$store.commit('INITIALISE_STORE') },
  render: h => h(App)
}).$mount('#app')
