<template>
  <b-col class="text-right my-2 p-0">
      <b-nav-item-dropdown class="profile-menu d-none d-lg-block" v-if="signedUser" :text="signedUser.name" right>
        <!-- <b-dropdown-item to="/profile">My Profile</b-dropdown-item> -->
         <!-- <b-dropdown-item to="/orders">View Buyback</b-dropdown-item> -->
        <b-dropdown-item to="/buybacklist" >View Batch</b-dropdown-item>
        <!-- <b-dropdown-item to="/faq">FAQs</b-dropdown-item> --> 
        <b-dropdown-item @click="signOut()">Sign Out</b-dropdown-item>
      </b-nav-item-dropdown>
    <b-col class="user my-auto pr-0 d-none d-lg-block">
      <b-button v-if="!signedUser" variant="link" v-b-modal.modalSignIn class=" text-left btn-sign-in text-decoration-none py-0">Partner Sign In</b-button>
    </b-col>
    <b-nav-item-dropdown class="profile-menu d-lg-none py-0" v-if="signedUser" :text="signedUser.name" right style="color: black !important;">
        <!-- <b-dropdown-item to="/orders">View Buyback</b-dropdown-item> -->
        <!-- <b-dropdown-item to="/buybacklist" v-if="signedUser.name === 'Demo Admin'">View Batch</b-dropdown-item> -->
        <!-- <b-dropdown-item to="/faq">FAQs</b-dropdown-item> -->
        <b-dropdown-item @click="signOut()">Sign Out</b-dropdown-item>
      </b-nav-item-dropdown>
    <b-col class="user my-auto pr-0 d-lg-none ">
      <b-button v-if="!signedUser" variant="link" v-b-modal.modalSignIn class="btn-sign-in text-decoration-none py-0">Sign In</b-button>
    </b-col>
    <b-modal
    hide-header-close ='true'
    no-close-on-backdrop = 'false'
      @hide="hideSignIn()"
      id="modalSignIn"
      centered
      title=""
      footer-class="border-0 mx-auto"
      header-class="border-0"
    >
      <template #modal-header="{}">
      </template>
      <b-tabs
        v-model="tabIndex"
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        active-tab-class=""
        content-class="mt-3"
      >
        <b-tab title="Partner Sign In" active>
          <b-form-input
            placeholder="Email"
            :state="userNameState"
            v-model="loginName"
            class="mb-3 border-top-0 border-left-0 border-right-0"
          ></b-form-input>
          <b-form-input
            @keydown.enter.native="submitTab()"
            placeholder="Pasword"
            type="password"
            :state="passwordState"
            v-model="userPassword"
            class="mb-3 border-top-0 border-left-0 border-right-0"
          ></b-form-input>
          <p class="text-danger text-center mb-0">{{loginMessage}}</p>
          <a class="ml-3" href="" @click="setActive()">Forgot Password?</a>

        </b-tab>
        <b-tab title="Partner Sign Up">
          <div class="d-flex">
            <b-form-input
            placeholder="Name"
            :state="nameState"
            v-model="userName"
            class="mb-3 border-top-0 border-left-0 border-right-0"
          ></b-form-input>
          <b-form-input
            placeholder="Email"
            :state="emailState"
            v-model="userEmail"
            class="mb-3 border-top-0 border-left-0 border-right-0 ml-3"
          ></b-form-input>
          </div>
          <div class="d-flex">
            <div class="d-flex">
              <vue-country-dropdown style="height: fit-content;padding: 0.06em;" class=" border-top-0 border-left-0 border-right-0"
              @onSelect="onSelect"
              :preferredCountries="['TR', 'US', 'GB']"
              :disabledFetchingCountry="true"
              :defaultCountryByAreaCode="90"
              :immediateCallSelectEvent="true"
              :enabledFlags="false"
              :enabledCountryCode="true"
              :showNameInput="false"
              :showNotSelectedOption="true"
              :notSelectedOptionText="'Not Selected'"/>
              <b-form-input
              type="text"
              onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
              max="999999999"
              placeholder="Phone Number"
              :state="mobileState"
              v-model="userMobile"
              class="input-login border-top-0 border-left-0 border-right-0"
              :formatter="mobileFormatter"
              ></b-form-input>
            </div>
           
              <b-form-input
              style="width: fit-content;"
              placeholder="Current Position"
              :state="namePosition"
              v-model="userPosition"
              class="mb-3 border-top-0 border-left-0 border-right-0 ml-3"
            ></b-form-input>
              
          </div>
          <div class="d-flex">
            <b-form-input
            type="password"
            placeholder="Password"
            :state="passwordState"
            v-model="userPassword"
            class="mb-3 border-top-0 border-left-0 border-right-0"
            ></b-form-input>
            <b-form-input
              type="password"
              placeholder="Confirm Password"
              :state="passwordConfirmState"
              v-model="confirmPassword"
              class="mb-3 border-top-0 border-left-0 border-right-0 ml-3"
            ></b-form-input>
          </div>
          <div  class="d-flex">
            <b-form-input
            placeholder="Company Name"
            :state="nameCompany"
            v-model="userCompany"
            class="mb-3 border-top-0 border-left-0 border-right-0"
          ></b-form-input>
          <b-form-input
            placeholder="Company Address"
            :state="nameAddress"
            v-model="userAddress"
            class="mb-3 border-top-0 border-left-0 border-right-0 ml-3"
          ></b-form-input>
          </div>
          <p class="text-danger text-center mb-0">{{signUpMessage}}</p>
          <div  class=" mt-3">
            <b-form-group label="OnBoarding-Form Download:">
              <button style="border-radius: 5px;" ><a href="https://docs.google.com/document/d/1Le191ID3ACebwWujwSvL9SMaB0jBqfIq/edit"  target="_blank" >Download</a></button>
            </b-form-group>
            <b-form-group label="OnBoarding Form Upload:">
              <input type="file" accept="application/pdf" class="fileinput"  @change="onChange5">
            </b-form-group>
          </div>
          <div  class="d-flex mt-3">
            <b-form-group label="Trade License:">
              <input type="file" accept="application/pdf" class="fileinput"  @change="onChange">
            </b-form-group>
            <b-form-group label="MOA:">
              <input type="file" accept="application/pdf" class="fileinput"  @change="onChange2">
            </b-form-group>
          </div>
          <div  class="d-flex">
            <b-form-group label="Valid ID:" >
              <input type="file" accept="application/pdf" class="fileinput"  @change="onChange3">
            </b-form-group>
            <b-form-group label="Vat Certificate:" >
              <input type="file" accept="application/pdf" class="fileinput"  @change="onChange4">
            </b-form-group>
          </div>
          
         </b-tab>
      </b-tabs>
      <template #modal-footer="{}">
        <b-button squared variant="success" @click="submitTab()" style="font-size: x-large;"
        >{{btnLabel}}</b-button
        >
        
      </template>
    </b-modal>
    
    <b-modal
      id="modalVerification"
      centered
      title=""
      footer-class="border-0 mx-auto"
      header-class="border-0"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
    >
      <template #modal-header="{}">
      </template>
      <b-col class="wraper">
        <div  class="register-wraper">
          <div  class="otp-generator">
            <!-- <div class="thanks-wrap">
              <p class="thanks-text">Thank you for your request</p>
            </div> -->
            <p class="otp-title">Thank you for your Request</p>
            <p class="thanks-text mt-4">We have received your information and may reach out to you for further clarification within 2 business days.</p>
           
            <!-- <div style="max-width: 200px; margin: 0 auto">
              <b-button v-if="!mobileFieldOpen" squared block variant="outline-success" class="mb-2" @click="editField()">Signout</b-button>
            </div> -->
          </div>
        </div>
      </b-col>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
  </b-col>
</template>

<script>

import api from '../api'
import { BIconArrowLeft } from 'bootstrap-vue'
import GoogleLogin from 'vue-google-login';
import VueCountryDropdown from 'vue-country-dropdown'
export default {
  name: 'SignIn',
  props: [],
  components: {
    BIconArrowLeft,
    GoogleLogin,
    VueCountryDropdown
  },
  data () {
    return {
      country:null,
      file1:null,
      file2:null,
      file3:null,
      file4:null,
      file5:null,
      position: null,
      userName: this.$store.state.signUpForm ? this.$store.state.signUpForm.name : '',
      userEmail: this.$store.state.signUpForm ? this.$store.state.signUpForm.em1ail : '',
      userPosition: this.$store.state.signUpForm ? this.$store.state.signUpForm.position : '',
      userCompany: this.$store.state.signUpForm ? this.$store.state.signUpForm.company : '',
      userMobile: this.$store.state.signUpForm ? this.$store.state.signUpForm.mobile : '',
      userAddress: this.$store.state.signUpForm ? this.$store.state.signUpForm.address : '',
      userPassword: '',
      loginName: '',
      confirmPassword: '',
      loginMessage: '',
      signUpMessage: '',
      tabIndex: 0,
      regStep: 0,
      otpGenerated: '',
      otpVerifyText: '',
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      isMobileVerified: false,
      userId: null,
      mobileFieldOpen: false,
      emailFieldOpen: false,
      params: {
        client_id: "127145128745-chlduhthucp87gk1unmibbl96g6vjsvm.apps.googleusercontent.com"
        },
        // only needed if you want to render the button with the google ui
            renderParams: {
            width: 250,
            height: 50,
            longtitle: true
        }
    }
  },
  computed: {
    signedUser: function () {
      return this.$store.state.user
    },
    isSignUp: function () {
      if (this.$store.state.signUpForm === null) {
        return false
      } else {
        return true
      }
    },
    maskedEmail: function () {
      var atIndex = this.userEmail.indexOf('@')
      if (this.userEmail && atIndex !== -1) {
        return this.userEmail.substr(0, 1) + '******' + this.userEmail.substr(atIndex)
      } else {
        return ''
      }
    },
    maskedMobile: function () {
      if (this.userMobile && this.userMobile.length >= 9) {
        return '971 ' + this.userMobile
      } else {
        return ''
      }
    },
    btnLabel: function () {
      if (this.tabIndex) {
        return 'Sign Up'
      } else {
        return 'Sign In'
      }
    },
    emailState: function () {
      if (this.userEmail.length) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(this.userEmail).toLowerCase())
      } else {
        return null
      }
    },
    userNameState: function () {
      if (this.loginName.length) {
        return this.loginName.length > 2
      } else {
        return null
      }
    },
    mobileState: function () {
      if (this.userMobile.length) {
        return this.userMobile.length === 9
      } else {
        return null
      }
    },
    nameState: function () {
      if (this.userName.length) {
        return this.userName.length > 2
      } else {
        return null
      }
    },
    namePosition: function () {
      if (this.userPosition.length) {
        return this.userPosition.length > 2
      } else {
        return null
      }
    },
    nameCompany: function () {
      if (this.userCompany.length) {
        return this.userCompany.length > 2
      } else {
        return null
      }
    },
    nameAddress: function () {
      if (this.userAddress.length) {
        return this.userAddress.length > 2
      } else {
        return null
      }
    },
    passwordState: function () {
      if (this.userPassword.length) {
        return this.userPassword.length > 3
      } else {
        return null
      }
    },
    passwordConfirmState: function () {
      if (this.userPassword.length && this.confirmPassword.length) {
        return this.confirmPassword === this.userPassword && this.userPassword.length > 3
      } else {
        return null
      }
    }
  },
  methods: {
    onSuccess(googleUser) {
      console.log(googleUser);

      // This only gets the user information: id, name, imageUrl and email
      console.log(googleUser.getBasicProfile());
    },
    handleOTPInput1: function (value) {
      if (value !== '' || value !== null) {
        try {
          this.$refs.otpInput2.focus()
        } catch (e) {
          console.log(e)
        }
      }
    },
    handleOTPInput2: function (value) {
      if (value !== '' || value !== null) {
        try {
          this.$refs.otpInput3.focus()
        } catch (e) {
          console.log(e)
        }
      }
    },
    handleOTPInput3: function (value) {
      if (value !== '' || value !== null) {
        try {
          this.$refs.otpInput4.focus()
        } catch (e) {
          console.log(e)
        }
      }
    },
    mobileFormatter: function (e) {
      return String(e).substring(0, 9)
    },
    otpFormatter: function (e) {
      return String(e).substring(0, 1)
    },
    verifyOTPF: function () {
      this.otpVerifyText = ''
      const otpEntered = this.otp1 + this.otp2 + this.otp3 + this.otp4
      console.log('otp', otpEntered)
      if (this.otpGenerated.toString() === otpEntered.toString()) {
        this.otpVerifyText = 'Mobile Number verified'
        this.isMobileVerified = true
        this.regStep = 3
        this.$store.commit('SET_MOBILE_VERIFIED', this.isMobileVerified)
      } else {
        this.otpVerifyText = 'Invalid OTP'
      }
    },
    verifyOTP: function () {
      const otpEntered = this.otp1 + this.otp2 + this.otp3 + this.otp4
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'res.users',
          'method': 'verify_user_otp',
          'args': {
            'user_id': this.userId,
            'otp': otpEntered
          }
        },
        'modecode': 100
      }
      api
        .request('post', 'update', inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            console.log(data.errormessage)
          }
          console.log(data.result)
          if (data.result && typeof data.result[0] === 'object' && data.result[0].mobile_verified === true) {
            this.otpVerifyText = 'Mobile Number verified'
            this.isMobileVerified = true
            this.regStep = 3
            this.$store.commit('SET_MOBILE_VERIFIED', this.isMobileVerified)
          } else {
            this.otpVerifyText = 'OTP verification Failed!'
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    setMobileVerified: function () {
      if (this.$store.state.user && this.$store.state.mobileVerified === true) {
        const inputs = {
          'inputs': {
            'session_id': this.$store.state.token,
            'model': 'res.users',
            'method': 'update_user_data',
            'args': { 'user_id': this.$store.state.user.uid, 'mobile_verified': this.isMobileVerified }
          },
          'modecode': 100
        }
        api.request('post', 'update', inputs).then((response) => {
          if (response.data && response.data.result) {
            let user = this.$store.state.user
            const contacts = user.contact_address
            const primaryContact = contacts[0]
            const updatedPrimaryContact = { ...primaryContact, mobile_verified: this.isMobileVerified }
            contacts[0] = updatedPrimaryContact
            user = { ...this.$store.state.user, contact_address: contacts }
            this.$store.commit('SET_USER', user)
            this.$store.commit('SET_MOBILE_VERIFIED', null)
          }
        }).catch(() => {
          alert('Somthing went wrong while verifying your number. Please try again later. If this issue persist please contact us')
        })
      }
    },
    closeVerification: function () {
      setTimeout(() => this.$bvModal.hide('modalVerification'), 10)
      this.signIn()
    },
    getOTPF: function () {
      this.regStep = 2
      this.otpGenerated = Math.floor(1000 + Math.random() * 9000)
      const text = 'Dear Customer, OTP for verifying your mobile number is ' + this.otpGenerated
      const inputs = {
        'inputs': {
          'mobile': '971' + this.userMobile,
          'text': text
        },
        'modecode': 100
      }
      api
        .request('post', 'message', inputs)
        .then((response) => {
          console.log('API Response:', response)
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    getOTP: function () {
      this.regStep = 2
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'res.users',
          'method': 'generate_user_otp',
          'args': {
            'user_id': this.userId,
            'mobile': '+971' + this.userMobile
          }
        },
        'modecode': 100
      }
      api
        .request('post', 'update', inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            console.log(data.errormessage)
          }
          if (data.result) {
            console.log(data.result)
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    sendEmail: function () {
      this.regStep = 4
      const inputs = {
        'inputs': {
          'email': this.userEmail,
          'name': this.userName,
          'user_id': this.userId,
          'session_id': this.$store.state.token
        },
        'modecode': 100
      }
      api
        .request('post', 'verify-email', inputs)
        .then((response) => {
          console.log('API Response:', response)
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    signOut: function () {
      this.$store.commit('CEAR_CART')
      this.$store.commit('SET_USER', null)
      localStorage.removeItem("user_token")
      localStorage.removeItem("token_data")
    },
    submitTab: function () {
      if (this.tabIndex) {
        this.signUp()
      } else {
        if (this.userNameState && this.passwordState) {
          this.signIn()
        }
      }
    },
    hideSignIn: function () {
      setTimeout(() => {
        if (this.$store.state.user === null) {
          this.$store.commit('UPDATE_CHECKOUT', 'login')
        }
      }, 500)
    },
    signIn: function () {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'r.users',
          'method': 'get_data_signin',
          'args': { 
          'email': this.loginName,
          'password': this.userPassword, 
          'red': true
          }
        },
        'modecode': 100
        // 'inputs': {
        //   'login': this.loginName,
        //   'password': this.userPassword,
        //   'red': true

        // },
        // 'modecode': 100
      }
      console.log('Sign In input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            this.loginMessage = 'Invalid Credentials!'
            return
          }
          if (data.result) {
            if (data.result.uid) {
              this.$store.commit('SET_USER', data.result)
              setTimeout(() => this.$bvModal.hide('modalSignIn'), 10)
            }
            else {
              this.loginMessage = 'Invalid Credentials!'
              window.location.reload()

            }
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    }, 
    signUp: function () {
      this.signUpMessage = ''
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'cor.user',
          'method': 'data_user_creation',
          'args': { 'name': this.userName,
          'login': this.userEmail,
          'password': this.userPassword,
          'mobile': '971' + this.userMobile,
          'position': this.userPosition,
          'address': this.userAddress,
          'country':this.country,     
          'company': this.userCompany,
          'attachment': this.file1.replace('data:', '').replace(/^.+,/, ''),
          'file_type':'Trade',
          'type':'pdf',
          'attachment2': this.file2.replace('data:', '').replace(/^.+,/, ''),
          'file_type2':'MOA',
          'type':'pdf',
          'attachment3': this.file3.replace('data:', '').replace(/^.+,/, ''),
          'file_type3':'ID',
          'type':'pdf',
          'attachment4': this.file4.replace('data:', '').replace(/^.+,/, ''),
          'file_type4':'Vat',
          'type':'pdf',
          'attachment5': this.file5.replace('data:', '').replace(/^.+,/, ''),
          'file_type5':'form',
          'type':'pdf',

         }
        },
        'modecode': 100
      }
      console.log('User Creation input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('user creation restposnse:', data.result)
            // this.userPassword = 'password'
            this.loginName = this.userEmail
            if (data.result[0].message === 'User Already Exist') {
              this.signUpMessage = 'Email or Phone Number Already Exists!'
            } else {
              // this.signIn()
              this.userId = data.result[0].user_id[0][0]
              setTimeout(() => this.$bvModal.hide('modalSignIn'), 10)
              setTimeout(() => this.$bvModal.show('modalVerification'), 10)
              this.regStep = 1
            }
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    approveAmount: function () {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'job.registration',
          'method': 'approve_job',
          'args': {
            'job_order_id': this.$store.state.activeOrder.job_name
          }
        },
        'modecode': 100
      }
      console.log('Orders Approve input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('Orders Approve restposnse:', data.result)
            this.$bvToast.toast(data.result[0], {
              title: 'Approved',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
            setTimeout(function () {
              self.$router.push('/')
            }, 1000)
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    verifyEmail: function () {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'res.users',
          'method': 'update_email_verified',
          'args': {
            'user_id': this.$store.state.user.uid,
            'email_verified': 'True'
          }
        },
        'modecode': 100
      }
      console.log('Verify Email Update input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('Verify Email restposnse:', data.result)
            this.$bvToast.toast('Your email has been verified!', {
              title: 'Email Verified',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
            setTimeout(function () {
              self.$router.push('/')
            }, 1000)
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },

    setActive: function () {
        this.$router.push({ name: 'ForgotPassword' })
        },
    editField: function () {
      this.mobileFieldOpen = true
      this.emailFieldOpen = true
    },
    getData: function () {
        const inputs = {
          'inputs': {
            'session_id': this.$store.state.token,
            'model': 'r.users',
            'method': 'get_data',
            'args': {
              'user_id': this.signedUser.uid
            }
          },
          'modecode': 100
        }
        const url = 'dataset'
        api
          .request('post', 'update', inputs)
          .then((response) => {
            var data = response.data
            if (data.errorcode) {
              // this.response = data.errormessage
              return
            }
            if (data.result) {
              this.position = data.result
              console.log( data.result)
            }
          })
          .catch((error) => {
            console.log('What happened? ' + error)
          })
      },
    saveField: function () {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'res.users',
          'method': 'update_user_data',
          'args': {
            'user_id': this.userId,
            'mobile': '971' + this.userMobile,
            'email': this.userEmail,
            'login': this.userEmail
          }
        },
        'modecode': 100
      }
      api.request('post', 'update', inputs).then((response) => {
        var data = response.data
        if (data.errorcode) {
          throw data.errorcode
        }
        if (data.result) {
          this.mobileFieldOpen = false
          this.emailFieldOpen = false
        }
      }).catch((error) => {
        alert(error)
      })
    },
    onChange(e) {
      console.log(e);
      if (! e.target.files.length) return;
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
          let src = e.target.result;
          this.file1 = e.target.result;
          console.log(e.target.result)

      };
    },
    onChange2(e) {
      console.log(e);
      if (! e.target.files.length) return;
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
          let src = e.target.result;
          this.file2 = e.target.result;

      };
    },
    onChange3(e) {
      console.log(e);
      if (! e.target.files.length) return;
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
          let src = e.target.result;
          this.file3 = e.target.result;

      };
    },
    onChange4(e) {
      console.log(e);
      if (! e.target.files.length) return;
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
          let src = e.target.result;
          this.file4 = e.target.result;

      };
    },
    onChange5(e) {
      console.log(e);
      if (! e.target.files.length) return;
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
          let src = e.target.result;
          this.file5 = e.target.result;

      };
    },
    onSelect({name, iso2, dialCode}) {
       console.log(name, iso2, dialCode);
       this.country = name
     },
  },
  mounted () {
    this.getData()
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      console.log('Modal is about to be shown', bvEvent, modalId)
      if (modalId === 'modalSignIn') {
        this.userName = this.$store.state.signUpForm ? this.$store.state.signUpForm.name : ''
        this.userEmail = this.$store.state.signUpForm ? this.$store.state.signUpForm.email : ''
        this.userMobile = this.$store.state.signUpForm ? this.$store.state.signUpForm.mobile : ''
      }
    })
  }
}
</script>

<style>
.user{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.user span {
  display: block;
  width: 110px;
  text-align: left;
  line-height: 12px;
  font-size: 12px;
  /* background-image: url('../assets/imgs/signin.png'); */
  background-repeat: no-repeat;
  background-size: 17px;
  padding-left: 24px;
  color: #333530;
}
.btn-sign-in{
  /* background-image: url('../assets/imgs/signin.png'); */
  background-repeat: no-repeat;
  background-size: 17px;
  padding-left: 24px;
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #333530;
}
.btn-sign-in:hover {
  /* background-image: url('../assets/imgs/signined.png'); */
  color: #28a745;
}
.nav-tabs .nav-link{
  color: #000;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  border-width: 0px 0px 3px 0px;
  border-top: 0px;
  border-color: #ffffff #ffffff #28a745 #ffffff;
}
.navbar-dark .navbar-nav .nav-link{
  color: #333530;
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus{
  color: #333530;
}
.profile-menu .dropdown-item.active, .profile-menu .dropdown-item:active{
  background-color: #28a745;
}
.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active{
  color: #333530;
}
</style>

<style scoped>
.hidden, .show {
  transform: none;
}
.register-wraper{
  max-width: 300px;
  margin: 0 auto;
  border: 2px solid #ccc;
  padding: 10px 15px;
  text-align: center;
}
.reg-type-selector-list{
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}
.title{
  font-size: 16px;
  font-weight: bold;
  color: #777;
  margin-top: 15px;
}
.input-login{
  border-top: 0px;
}
.phone-code{
  background-color: #fff;
  border-top:0;
  border-left: 0;
}
.register-form .input-login {
  border-left: 0;
  border-right: 0;
}
.input-login:focus{
  border-color: #3FAF2B;
  border-top: 1px solid #3FAF2B;
  border-left: 1px solid #3FAF2B;
  border-right: 1px solid #3FAF2B;
  box-shadow: none;
}
.link{
  color:#3FAF2B;
  margin-top: 10px;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
}
.skip-link, .skip-link:hover{
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  color: rgba(56, 57, 52, 0.51);
}
.skip-link:hover{
  color: rgb(56, 57, 52);
}
.thanks-wrap{
  margin-top: 30px;
  border-bottom: 1px solid #E5E5E5;
  margin-bottom: 30px;
}
.thanks-text{
  color: rgba(56, 57, 52, 0.71);
}
.otp-title{
  color: #3FAF2B;
  font-weight: bold;
  font-size: 24px;
}
.text-dark-grey{
  color: rgb(56, 57, 52);
}
.resend-email-des{
  color: rgba(56, 57, 52, 0.71);
  margin-top: 20px;
  margin-bottom: 20px;
}
.resend-otp-text, .resend-email-label{
  color: rgb(56, 57, 52);
  font-size: 12px;
  font-weight: bold;
  margin-top: 30px
}
.resend-email-label{
  margin-bottom: 0px;
}
.resend-email-text .link {
  font-size: 12px;
}
.resend-otp-text .link {
  font-size: 12px;
}
.otp-input{
  background-color: rgba(56, 57, 52, 0.05);
}
@media (min-width: 668px) {
  .register-wraper{
    max-width: 520px;
  }
}
.otp-inputnew {
  width: 45px;
  height: 40px;
  padding: 5px;
  margin: 0 10px !important;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-style: dashed;
  text-align: center;
  margin-right: 50px;
}
/* Background colour of an input field with value */
.otp-inputnew.is-complete {
  background-color: #e4e4e4;
}
.otp-inputnew::-webkit-inner-spin-button,
.otp-inputnew::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
.google-signin-btn-3 {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: transparent;
  color: rgb(71, 68, 68);
  box-shadow: 0 1px 0 #a0a7b3;
  font-weight: bolder;
  font-size: x-large;
  border-radius: 10px;
}
a {
  color: #000000 !important;
}
span {
  color: #000000 !important;

}
</style>
