<template>
  <div>
    <Header class="head"/>
    <div class="mt-5 pb-2 print" >
    <div class="d-flex justify-content-center mt-3" >
      <b-container class=" d-flex justify-content-center" >
        <div  class=" mb-2 ">
          <div class="home">
          <!-- <div class="row justify-content-end" v-if="order[0].status === false">          
            <b-button @click="ChangeOrderDetails('Accepted')" class="button mr-3 mb-3">Accpect</b-button>
            <b-button @click="ChangeOrderDetails('Rejected')" class="button mr-3 mb-3">Reject</b-button>
          </div>
          <div class="row justify-content-end" v-if="order[0].status === 'Accepted' && order[0].batch_status === false">          
            <b-button @click="Show_Schedule()" class="button mr-3 mb-3">Set a Schedule</b-button>
          </div>
          <div class="row justify-content-end" v-else>          
            <p class="button mr-3 mb-3">Status: Waiting for Inspection</p>
          </div> -->
      <div class="thanks p-5 info-box border border-secondary mb-2 "  id="section-to-print">
        <div class="text-center mt-5">
        <h2>Estimation</h2>
        </div>
        <div class="text-left mt-3" >
          <div class="d-flex justify-content-between">
            <div>
              <p><b>Date:</b> {{order[0].date}}</p>
              <p><b>Reference#</b> {{order[0].ids}}</p>
            </div>
            <div>
              <h5><u>Customer Details</u></h5>
              <p class="mt-1"><b>Name:</b> {{order[0].user}}</p>
              <!-- <p><b>Email:</b> {{order[0].email}}</p> -->
              <p><b>Contact:</b> {{order[0].num}}</p>
              <p><b>Place:</b> {{order[0].add}}</p>
              <!-- <qr-code :text="'https://redington.r3factory.com/orders/sell/'+id" size="100"></qr-code> -->
            </div>
          </div>
       
        <br/>
        
        <div class="mt-5">
          <table id="customers">
            <tr>
              <th>Product Description</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Condition</th>
              <th>Estimated Value</th>
            </tr>
            <tr v-for="i in order" :key="i">
              <td>{{i.name}}</td>
              <td>{{i.qty}}</td>
              <td>{{i.unit}}</td>
              <td>{{i.grade}}</td>
              <td>{{i.value}}</td>
            </tr>
          </table>
        </div>
        <br/>
        <hr/>
        <div class="d-flex justify-content-end">
          <div style="width: fit-content;">
            <div class="d-flex justify-content-around">
              <h5>
                Total Quantity
              </h5>
              <h5 class="ml-5">
                {{order[0].total_qty}}.00 
              </h5>
            </div>
            <hr/>
            <div class="d-flex justify-content-around mt-3">
              <h5>
                Total Price
              </h5>
              <h5 class="ml-5">
                {{order[0].total_price}}.00 
              </h5>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-5">          
            <b-button @click="Enter_Details()" class="button mr-3 mb-3" style="font-size: larger;">Start Inspection</b-button>
          </div>
        </div>
      </div>
    </div>
        </div>
        </b-container>
        </div>
  </div>
      <b-modal
        id="modal-Schedule"
        ref="modal"
        scrollable
        title="Batch Details"
        @show="resetModal"
        @hidden="resetModal"
        hide-footer
        @ok="handleOk">
        <b-navbar toggleable type="light" variant="light" class="mt-3"  v-for="i in order" :key="i">
          <b-navbar-brand href="#">{{ i.name }}</b-navbar-brand>

          <b-navbar-toggle :target="i.idd2">
            <template #default="{ expanded }">
              <b-icon v-if="expanded" icon="chevron-bar-up"><BIconChevronUp/></b-icon>
              <b-icon v-else icon="chevron-bar-down"><BIconChevronDown/></b-icon>
            </template>
          </b-navbar-toggle>

          <b-collapse :id="i.idd2" is-nav>
            <div>
              <div  v-for="i2 in parseInt(i.qty)" :key="i2" class="d-flex ">
                <b-form-input style="width: fit-content;" class="mt-3"  :state="imeiState" :formatter="imeiFormatter" v-model="text5[i2]" :placeholder="'Device IMEI '+i2"></b-form-input>
                <b-form-select v-model="selected[i2]" :options="options"  class="mt-3 ml-3"></b-form-select>
              </div>
            </div>
            <div class="row justify-content-center mt-5">          
              <b-button @click="Finish_Details(i.name)" class="button mr-3 mb-3" style="font-size: larger;">Confirm Device</b-button>
            </div>
          </b-collapse>
          
        </b-navbar>
        
    </b-modal>
  </div>
</template>

<script>
import api from '../api'
import Header from '../components/Header.vue'
import { BIconChevronUp, BIconChevronDown } from 'bootstrap-vue'

export default {
  name: 'ThanksPage',
  props: {
    orderno: ''
  },
  components: {
    Header,
    BIconChevronUp,
    BIconChevronDown
  },
  computed: {
    customerList: function () {
        return this.$store.state.reciept
    },
  },
  data () {
    return {
      text5: [],
      types: [
          'Name',
          'Location',
          'mobile',
          'date',
          'time',
        ],
      Name: '',
      Location: '',
      mobile: '',
      date: '',
      time: '',
      id: this.$route.params.id,
      order: null,
      selected: [],
      options: [
        { value: null, text: 'Select a Condition' },
        { value: 'A', text: 'Condition 1' },
        { value: 'B', text: 'Condition 2' },
        { value: 'C', text: 'Condition 3' }
      ]
    }
  },
  methods: {
    print_rsp: function () {
      this.order = true
      window.print();
    },
    getOrderDetails: function () {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'cor.batch',
          'method': 'get_batch_data2',
          'args': {
            'id': this.id
          }
        },
        'modecode': 100
      }
      api.request('post', 'update', inputs).then((response) => {
        if (response.data.errorcode) {
          this.errormessage = response.data.errormessage
        } else if (response.data.result && typeof response.data.result === 'object') {
          this.order = response.data.result
          // console.log(this.order)
        } else if (response.data.result && typeof response.data.result === 'string') {
          this.errormessage = response.data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    ChangeOrderDetails: function (status) {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'cor.batch',
          'method': 'get_status_data',
          'args': {
            'id': this.id,
            'status':status
          }
        },
        'modecode': 100
      }
      api.request('post', 'update', inputs).then((response) => {
        if (response.data.errorcode) {
          this.errormessage = response.data.errormessage
        } else if (response.data.result) {
          this.$router.push({ name: 'LandingPage' })

        } else if (response.data.result && typeof response.data.result === 'string') {
          this.errormessage = response.data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    ScheduleDetails: function (status) {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'cor.veri',
          'method': 'cos_veri_creation',
          'args': {
            'user_id': this.$store.state.user.uid,
            'id': this.id,
            'Name':this.Name,
            'Location':this.Location,
            'number':this.mobile,
            'date':this.date,
            'time':this.time
          }
        },
        'modecode': 100
      }
      api.request('post', 'update', inputs).then((response) => {
        if (response.data.errorcode) {
          this.errormessage = response.data.errormessage
        } else if (response.data.result) {
          this.$router.push({ name: 'LandingPage' })

        } else if (response.data.result && typeof response.data.result === 'string') {
          this.errormessage = response.data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    Finish_Details: function (device) {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'cor.veri_com',
          'method': 'cos_veri_com_creation',
          'args': {
            'user_id': this.$store.state.user.uid,
            'id': this.id,
            'imei': this.text5,
            'con': this.selected,
            'device': device
          }
        },
        'modecode': 100
      }
      api.request('post', 'update', inputs).then((response) => {
        if (response.data.errorcode) {
          this.errormessage = response.data.errormessage
        } else if (response.data.result) {
          // this.$router.push({ name: 'LandingPage' })

        } else if (response.data.result && typeof response.data.result === 'string') {
          this.errormessage = response.data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    Enter_Details: function () {
      this.$bvModal.show('modal-Schedule')

    }
  },
  mounted () {
    this.getOrderDetails()
  }
}
</script>

<style scoped>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}
.thanks{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}
.hrc{
  border: 1px solid rgb(180, 180, 180);
  width: 60%;
}
.hrc2{
  border: 1px solid rgb(199, 197, 197);
  width: 100%;
}
p{
  font-size: larger;
  margin: 0;
}
.info-box{
  margin-right: 3px;
  margin-left: 3px;
  padding: 3px;
  border-radius: 7px;
  border-color: #e0e0ec !important;
  text-align: center;
}
.wdk_content-pagetitle {
display:none;
}
@media print {
  @page { margin: 0; }
  head * {
    visibility: hidden;
  }
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

</style>
