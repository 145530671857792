<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            Buyback Details
          </h3>
        </div>
      </div>
    </div>
    <div>
      <b-card no-body>
        <b-tabs  card vertical>
          <b-tab title="Product Details" active><b-card-text>
            <div class="block w-full overflow-x-auto">
              <table class="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th
                      class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      :class="[
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                      ]"
                    >
                    Product Category
                    </th>
                    <th
                      class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      :class="[
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                      ]"
                    >
                    Product Brand
                    </th>
                    <th
                      class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      :class="[
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                      ]"
                    >
                    Product Name
                    </th>
                    
                    <th
                      class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      :class="[
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                      ]"
                    >
                    IMEI / Serial No
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="i in data" :key="i" @click="getBuyback(i.id)">
                      <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {{ i.cate }}
                      </td>
                      <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {{ i.brand }}
                      </td>
                      <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {{ i.device }}
                      </td>
                      <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                        {{ i.imei }}
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card-text></b-tab>
          <b-tab title="Partner" style="padding: 0px !important;height: 10px !important;" ><b-card-text>
            <b-card no-body   style="padding: 0px !important; border: none; ">
              <b-tabs  align="center" style="padding: 0px !important; border: none !important;; ">
                <b-tab title="Partner" ><b-card-text>
                  <div class="block w-full overflow-x-auto">
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Partner ID
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Partner Name
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in data" :key="i" @click="getBuyback(i.id)">
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.partner_id }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.partner_name }}
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-text></b-tab>
                <b-tab title="Partner Location" ><b-card-text>
                  <div class="block w-full overflow-x-auto">
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          ID
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Address
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Name
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Email
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Number
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in data" :key="i" @click="getBuyback(i.id)">
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.partner_address_id }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.partner_address }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.partner_name_u }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.partner_email }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.partner_num }}
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-text></b-tab>
              </b-tabs>
            </b-card>
          </b-card-text></b-tab>
          <b-tab  v-if="!user.partner" title="Liquidator" style="padding: 0px !important;height: 10px !important;" ><b-card-text>
            <b-card no-body   style="padding: 0px !important; border: none; ">
              <b-tabs  align="center" style="padding: 0px !important; border: none !important;; ">
                <b-tab title="Liquidator ID" ><b-card-text>
                  <div class="block w-full overflow-x-auto">
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Liquidator ID
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Liquidator Name
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in data" :key="i" @click="getBuyback(i.id)">
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.vendor_id }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.vendor_name }}
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-text></b-tab>
                <b-tab title="Liquidator Location" ><b-card-text>
                  <div class="block w-full overflow-x-auto">
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          ID
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Address
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Name
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Number
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Reference
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in data" :key="i" @click="getBuyback(i.id)">
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.vendor_id }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.vendor_address }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.vendor_name }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.vendor_num }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.ref }}
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-text></b-tab>
              </b-tabs>
            </b-card>
          </b-card-text></b-tab>
          <b-tab title="Customer" style="padding: 0px !important;height: 10px !important;" ><b-card-text>
            <b-card no-body   style="padding: 0px !important; border: none; ">
              <b-tabs  align="center" style="padding: 0px !important; border: none !important;; ">
                <b-tab title="Customer Details" ><b-card-text>
                  <div class="block w-full overflow-x-auto">
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Customer ID
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Customer Name
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Customer Email
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Customer Number
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in data" :key="i" @click="getBuyback(i.id)">
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              C00{{ i.cus_id }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.cus_name }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.cus_email }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.cus_mobile }}
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-text></b-tab>
                <b-tab title="Customer ID" ><b-card-text>
                  <b-container fluid class="p-4 bg-dark"  v-if="this.$store.state.partner !== 'Saudi Arabia'">
                    <b-row  v-for="i in data"  >
                      <b-col v-for="i2 in i.cus_attach">
                        <b-img thumbnail fluid  :src="'data:image/png;base64,' + i2.file" alt="Image 1" @click="showimg(i2.name)"></b-img>
                        <b style="color: white;">{{ i2.name }}</b>
                        <b-modal :id="i2.name" :title="i2.name" hide-footer>
                          <img :src="'data:image/png;base64,' + i2.file" width=""/>
                        </b-modal>
                      </b-col>
                    </b-row>
                  
                  </b-container>
                  <b-container fluid class="p-4 "  v-else>
                    <div v-for="i in data" :key="i">
                      Card Number: {{ i.card_numer }}

                    </div>
                  </b-container>
                </b-card-text></b-tab>
              </b-tabs>
            </b-card>
          </b-card-text></b-tab>
          <b-tab title="Condition"><b-card-text>
            <div v-for="i in data" :key="i" v-if="i.cate === 'Mobile'">
              <h5  class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">Device Condition</h5>
              <ul class="text-left a px-6" v-if="i.grade === 'A'">
                <li>
                  No Scratches on Screen
                </li>
                <li>
                  Body has minor scratches,  No dents
                </li>
                <li>
                  Battery health 90% or Above
                </li>
                <li>
                  Fully functional
                </li>
                <li>
                  No Device Lock/Sim Lock
                </li>
                <li>
                  No missing parts (back cover, sim tray, buttons)
                </li>
              </ul>
              <ul class="text-left a px-6" v-if="i.grade === 'B'">
                <li>
                  Screen having Scratches, open or swapped
                </li>
                <li>
                  Major scratches / dents on back cover and housing.
                </li>
                <li>
                  Battery health 80% or Above
                </li>
                <li>
                  Issues with either mic, speaker or buttons
                </li>
                <li>
                  No Device Lock/Sim Lock
                </li>
                <li>
                  No missing parts (back cover, sim tray, buttons)
                </li>
              </ul>
              <ul class="text-left a px-6" v-if="i.grade === 'C'">
                <li>
                  LCD dots/ lines/ discoloration/Screen Burn, Screen Touch not working
                </li>
                <li>
                  Device cracked / Bent device 
                </li>
                <li>
                  Battery health: Service indicator on
                </li>
                <li>
                  Any one of the following not working (touch ID, face ID, camera)
                </li>
                <li>
                  Device Lock/Sim Lock
                </li>
                <li>
                  Missing parts (back cover, sim tray or buttons)
                </li>
              </ul>
            </div>
            <div v-for="i in data" :key="i" v-if="i.cate === 'Laptop'">
              <h5  class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">Device Condition</h5>
              <ul class="text-left a px-6" v-if="i.grade === 'A'">
                <li>
                  100% functional with charger.
                </li>
                <li>
                  No Scratches on screen

                </li>
                <li>
                  Minor scratches/ No Dents on back cover and housing

                </li>
                <li>
                  No missing parts (keys, back cover, battery)

                </li>
                <li>
                  Free from all locks (device lock, google lock, apple Lock or network lock)

                </li>
              </ul>
              <ul class="text-left a px-6" v-if="i.grade === 'B'">
                <li>
                  Fully functional

                </li>
                <li>
                  Major Scratches on the screen

                </li>
                <li>
                  Major Scratches / Major Dents on the back cover and housing

                </li>
                <li>
                  No missing parts (keys, back cover, battery)

                </li>
                <li>
                  Free from all locks (device lock, Google lock, Apple lock, or network lock)

                </li>
              </ul>
              <ul class="text-left a px-6" v-if="i.grade === 'C'">
                <li>
                  Faulty device/ Dead Unit (No power)

                </li>
                <li>
                  Broken Screen , LCD Dots/Lines/Discoloration

                </li>
                <li>
                  Cracked/Broken Camera, back cover, and housing

                </li>
                <li>
                  Any missing parts (keys, back cover, battery)

                </li>
                <li>
                  Device lock, Google lock, Apple lock, or network lock

                </li>
              </ul>
            </div>
            <div v-for="i in data" :key="i" v-if="i.cate === 'Smartwatches'">
              <h5  class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">Device Condition</h5>
              <ul class="text-left a px-6" v-if="i.grade === 'A'">
                <li>
                  No Scratches

                </li>
                <li>
                  Body has minor scratches,  No dents

                </li>
                <li>
                  Battery health 90% or Above

                </li>
                <li>
                  Fully functional

                </li>
                <li>
                  No Device Lock/Sim Lock

                </li>
                <li>
                  No missing parts (back cover, sim tray, buttons, Charger)

                </li>
              </ul>
              <ul class="text-left a px-6" v-if="i.grade === 'B'">
                <li>
                  Screen having Scratches, open or swapped

                </li>
                <li>
                  Major scratches / dents on back cover and housing.

                </li>
                <li>
                  Battery health 80% or Above

                </li>
                <li>
                  Issues with either mic, speaker or buttons (Mandatory Charger)

                </li>
                <li>
                  No Device Lock/Sim Lock

                </li>
                <li>
                  No missing parts (back cover, sim tray, buttons, Charger)

                </li>
              </ul>
              <ul class="text-left a px-6" v-if="i.grade === 'C'">
                <li>
                  LCD dots/ lines/ discoloration/Screen Burn, Screen Touch not working


                </li>
                <li>
                  Device cracked / Bent device 


                </li>
                <li>
                  Battery health: Service indicator on

                </li>
                <li>
                  Any one of the following not working (touch ID, face ID, camera)

                </li>
                <li>
                  Device Lock/Sim Lock

                </li>
                <li>
                  Missing parts (back cover, sim tray or buttons, Charger)

                </li>
              </ul>
            </div>
            <div v-for="i in data" :key="i" v-if="i.cate === 'Headphones & wireless audio'">
              <h5  class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">Device Condition</h5>
              <ul class="text-left a px-6" v-if="i.grade === 'A'">
                <li>
                  Fully Functional
                </li>
                <li>
                  No cracks or damage
                </li>
              </ul>
              <ul class="text-left a px-6" v-if="i.grade === 'B'">
                <li>
                  Faulty Device
                </li>
                <li>
                  Cracks / Damages
                </li>
              </ul>
            </div>
            <div v-for="i in data" :key="i" v-if="i.cate === 'Tablets'">
              <h5  class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">Device Condition</h5>
              <ul class="text-left a px-6" v-if="i.grade === 'A'">
                <li>
                  No Scratches


                </li>
                <li>
                  Body has minor scratches,  No dents


                </li>
                <li>
                  Battery health 90% or Above


                </li>
                <li>
                  Fully functional


                </li>
                <li>
                  No Device Lock/Sim Lock


                </li>
                <li>
                  No missing parts (back cover, sim tray, buttons)


                </li>
                <li>
                  Face ID working


                </li>
              </ul>
              <ul class="text-left a px-6" v-if="i.grade === 'B'">
                <li>
                  Screen having Scratches, open or swapped

                </li>
                <li>
                  Major scratches / dents on back cover and housing.


                </li>
                <li>
                  Battery health 80% or Above


                </li>
                <li>
                  Issues with either mic, speaker or buttons


                </li>
                <li>
                  No Device Lock/Sim Lock


                </li>
                <li>
                  No missing parts (back cover, sim tray, buttons)


                </li>
                <li>
                  Face ID working


                </li>
              </ul>
              <ul class="text-left a px-6" v-if="i.grade === 'C'">
                <li>
                  LCD dots/ lines/ discoloration/Screen Burn, Screen Touch not working

                </li>
                <li>
                  Device cracked / Bent device 


                </li>
                <li>
                  Battery health: Service indicator on


                </li>
                <li>
                  Any one of the following not working (touch ID, face ID, camera)


                </li>
                <li>
                  Device Lock/Sim Lock

                </li>
                <li>
                  Missing parts (back cover, sim tray or buttons)


                </li>
                <li>
                  Face ID not working


                </li>
              </ul>
            </div>
          </b-card-text></b-tab>
          <b-tab title="Trade-In"><b-card-text>
            <div class="block w-full overflow-x-auto">
              <table class="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th
                      class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      :class="[
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                      ]"
                    >
                    Reference ID
                    </th>
                    <th v-if="!user.partner"
                      class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      :class="[
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                      ]"
                    >
                    Offered to Retailer                    </th>
                    <th
                      class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      :class="[
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                      ]"
                    >
                    Offered to Customer                    </th>
                    
                    <th
                      class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      :class="[
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                      ]"
                    >
                    Status
                    </th>
                    <th
                      class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      :class="[
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                      ]"
                    >
                    Date
                    </th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="i in data" :key="i" @click="getBuyback(i.id)">
                      <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {{ i.id }}
                      </td>
                      <td v-if="!user.partner" class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {{ i.trade_total }}
                      </td>
                      <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {{ i.trade_retail }}
                      </td>
                      <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                        <i v-if="i.status === 'Complete'" class="fas fa-circle text-emerald-500 mr-2"></i>
                        <i v-else class="fas fa-circle text-orange-500 mr-2"></i>{{ i.status }}
                      </td>
                      <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {{ i.date }}
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card-text></b-tab>
          <b-tab title="Product Images"><b-card-text>
            <b-container fluid class="p-4 bg-dark" >
              <b-row  v-for="i in data"  >
                <b-col v-for="i2 in i.imgs" v-if="i2.name === 'four' || i2.name === 'five' || i2.name === 'six'">
                  <b-img thumbnail fluid  :src="'data:image/png;base64,' + i2.file" alt="Image 1" @click="showimg(i2.name)"></b-img>
                  <b style="color: white;">{{ i2.name }}</b>
                  <b-modal :id="i2.name" :title="i2.name" hide-footer>
                    <img :src="'data:image/png;base64,' + i2.file" width=""/>
                  </b-modal>
                </b-col>
              </b-row>
              <b-row  v-for="i in data"  >
                <b-col v-for="i2 in i.imgs" v-if="i2.name === 'one' || i2.name === 'two' || i2.name === 'three'">
                  <b-img thumbnail fluid  :src="'data:image/png;base64,' + i2.file" alt="Image 1" @click="showimg(i2.name)"></b-img>
                  <b style="color: white;">{{ i2.name }}</b>
                  <b-modal :id="i2.name" :title="i2.name" hide-footer>
                    <img :src="'data:image/png;base64,' + i2.file" width=""/>
                  </b-modal>
                </b-col>
              </b-row>
             
            </b-container>
          </b-card-text></b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<script>
import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";

import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import api from '../../api'

export default {
  data() {
    return {
      id: this.$route.params.id,
      bootstrap,
      angular,
      sketch,
      react,
      vue,
      team1,
      team2,
      team3,
      team4,
      data:null,
      user: this.$store.state.user,

    };
  },
  components: {
    TableDropdown,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    // getBuyback: function (id) {
    //   this.$router.push({ path: '/admin/batch/'+ id, params: { 'id': id } })
    // },
    showimg: function (id) {
      this.$bvModal.show(id)
    },
    getDetails: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
        
            'id': this.id,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
          
      }
      api.request(process.env.VUE_APP_Get_Data, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result && typeof data.result === 'object') {
          this.data = data.result
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    }
  },
  mounted () {
      this.getDetails()
  }
};
</script>

<style scoped>
ul.a {
      list-style-type: circle;
    }
</style>