<template>
  <div>
    <Header/>
    <div class="home mt-1">
      <div v-if="errormessage" class="container mt-2">
        <div  class="d-block m-md-5">{{errormessage}}</div>
      </div>
      <div v-else class="container mt-2">
        <div class="row">
          <div class="col">
              <p class="tite" style="font-size: 2.5em;">View Request</p>
          </div>
        </div>
        <div class="row justify-content-center">
        <order-list :orders="data" class="col-md-9"/>

        </div>
      </div>
    </div>
    <!-- <Footer/> -->
  </div>
</template>

<script>
import api from '../api'
import Header from '../components/Header.vue'
// import ServiceBar from '../components/ServiceBar.vue'
import Footer from '../components/Footer.vue'
import OrderList from '../components/OrderList.vue'

export default {
  name: 'Orders',
  data () {
    return {
      errormessage: false,
      data: false
    }
  },
  props: [],
  computed: {},
  components: {
    Header,
    // ServiceBar,
    Footer,
    OrderList
  },
  methods: {
    getOrders: function () {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'r.buyback',
          'method': 'get_data_list',
          'args': {
            'user_id': this.$store.state.user.uid
          }
        },
        'modecode': 100
      }
      api.request('post', 'update', inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result && typeof data.result === 'object') {
          this.data = data.result
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    }
  },
  mounted () {
    if (!this.$store.state.user) {
      this.errormessage = 'To view orders, please log in'
      this.$bvModal.show('modalSignIn')
    } else {
      this.getOrders()
    }
  }
}
</script>

<style scoped>
.info-box{
  margin-right: 0 3px;
}
.title-bar{
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 15px;
}
.tite{
  color: #37a531;
  font-family: 'Avenir Black', sans-serif;
}
</style>
