<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0" v-if="!user.brand">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            Batch List
          </h3>
        </div>
      </div>
    </div>
    <div class="rounded-t mb-0 px-4 py-3 border-0" v-if="user.brand">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            {{user.brand}} Promotions
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto" v-if="!user.brand">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Batch ID
            </th>
            <th v-if="!user.partner"
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Vendor
            </th>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Partner
            </th>
            
            
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Quantity
            </th>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Price
            </th>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Status
            </th>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Date
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in data" :key="i" @click="getBuyback(i.id)">
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                RB00{{ i.id }}
              </td>
              <td v-if="!user.partner" class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ i.name }}
              </td>
              <td  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ i.user }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                {{ i.qty }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                {{ i.price }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                <i v-if="i.status === 'Complete'" class="fas fa-circle text-emerald-500 mr-2"></i>
                <i v-else class="fas fa-circle text-orange-500 mr-2"></i>{{ i.status }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                {{ i.date }}
              </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="user.brand">
      <b-container class="bv-example-row d-flex" >
      <b-col v-if="selected_option !== 'A' && selected_option !== 'B'">
        <div>
          <b-form-input id="date" v-model="selected" type="date"></b-form-input>
          <div class="mt-3">Selected: <strong>{{ selected }}</strong></div>
        </div>
      </b-col>
      <b-col v-if="selected_option !== 'A' && selected_option !== 'B'">
        <div>
          <b-form-input id="date" v-model="selected1" type="date"></b-form-input>
          <div class="mt-3">Selected: <strong>{{ selected1 }}</strong></div>
        </div>
      </b-col>
      <b-button @click="getOrders3()" style="height: fit-content;">Confirm</b-button>
      </b-container>
      <div v-if="data" class="text-right">
              <vue-excel-xlsx :data="data" :columns="json_fields" :file-name="'file'" :file-type="'xlsx'"
                :sheet-name="'sheet'">
                <button type="button" class="btn btn-success">Download Excel</button>
      </vue-excel-xlsx>
     </div>
    </div>
    <div class="block w-full overflow-x-auto" v-if="user.brand">
      
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            ID
            </th>
            <th v-if="!user.partner"
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Location
            </th>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Partner
            </th>
            
            
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Quantity
            </th>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Price
            </th>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Status
            </th>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Date
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in data" :key="i" >
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ i.id }}
              </td>
              <td v-if="!user.partner" class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ i.name }}
              </td>
              <td  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ i.user }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                {{ i.qty }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                {{ i.price }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                <i v-if="i.status === 'Complete'" class="fas fa-circle text-emerald-500 mr-2"></i>
                <i v-else class="fas fa-circle text-orange-500 mr-2"></i>{{ i.status }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                {{ i.date }}
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";

import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import api from '../../api'

export default {
  data() {
    return {
      selected: null,
      selected1: null,
      bootstrap,
      angular,
      sketch,
      react,
      vue,
      team1,
      team2,
      team3,
      team4,
      data:null,
      user: this.$store.state.user,
      json_fields: [
    { label: "ID", field: "id" },
    { label: "Status", field: "status" },
    { label: "Partner", field: "user" },
    { label: "Location Name", field: "location" },
    { label: "Date", field: "date" },
    { label: "IMEI", field: "imei" },
    { label: "Trade-in Price offered to the Retailer", field: "trade_in" },
    { label: "Category", field: "cate" },
    { label: "Brand", field: "brand" },
    { label: "Device", field: "name" },
    { label: "Grade", field: "grade" },
    { label: "Promotion", field: "promo" },
    { label: "New Device", field: "new_name" },
    { label: "Top-up Value", field: "topup" },
    { label: "New Device IMEI", field: "new_imei" }
]
,
    };
  },
  components: {
    TableDropdown,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    getBuyback: function (id) {
      this.$router.push({ path: '/admin/batch/'+ id, params: { 'id': id } })
    },
    getOrders: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
            'user_id': this.$store.state.user.uid,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
            'country':this.$store.state.partner
          
      }
      api.request(process.env.VUE_APP_Get_Data_list2, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result && typeof data.result === 'object') {
          this.data = data.result
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    getOrders2: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
      
            'user_id': this.$store.state.user.uid,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
            
          
      }
      api.request(process.env.VUE_APP_Detail_List_Partner2, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result && typeof data.result === 'object') {
          this.data = data.result
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    getOrders3: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
       
            'user_id': this.$store.state.user.uid,
            'brand':this.$store.state.user.brand,
            'start':this.selected,
            'end':this.selected1,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
        
      }
      api.request(process.env.VUE_APP_Partner_Data_List_3, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result && typeof data.result === 'object') {
          this.data = data.result
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    }
  },
  mounted () {
    if (!this.$store.state.user) {
      this.errormessage = 'To view orders, please log in'
      this.$bvModal.show('modalSignIn')
    } else {
        if (this.user.partner === true) {
          this.getOrders2()
        } else if (this.user.brand) {
          // this.getOrders3()
        } else {
          this.getOrders()
        }
    }
  }
};
</script>
