<template>
    <div>
      <Header/>
        <div class="mt-5 pb-2 d-none d-lg-block">
          <h3 v-if="!brand_active">Choose Brand</h3>
          <h3 v-if="model_selected">Choose Brand</h3>
          <h3 v-if="pro_selected">Choose Model</h3>
  
          <h3 v-if="repair">Choose Condition</h3>
          <div class="d-flex justify-content-center mt-5" >
          <b-container class="bv-example-row d-flex">
            <div   class=" info-box border border-secondary mb-2 col-md-2" style="min-height: fit-content">
  
                <div  class="info-box border border-secondary mb-2 mt-4" style="min-height: 55px;">
                  <p class="mt-4">Tablets</p>
                </div>
                <div v-if="brand_active" class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
                <p class="mt-4">{{ brand_active }}</p>
              </div>
                <div  v-if="model_active"  class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
                  <p class="mt-4">{{ model_active }} Series</p>
                </div>
                <div  v-if="pro_active"  class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
                  <p class="mt-4">{{ pro_active }}</p>
                </div>
                <div  v-if="grade_active"  class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
                  <p class="mt-4">{{ grade_active }} inches</p>
                </div>
                <div  v-if="grade_active2"  class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
                  <p class="mt-4">{{ grade_active2 }}</p>
                </div>
                <!-- <div  v-if="grade_selected"  class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
                  <p  class="mt-4"  v-if="grade_selected === 'A'">Condition 1</p>
                  <p  class="mt-4" v-if="grade_selected === 'B'">Condition 2</p>
                  <p  class="mt-4" v-if="grade_selected === 'C'">Condition 3</p>
                </div>
                <div  v-if="value_selected"  class="info-box border border-secondary mb-2 mt-1 " style="min-height: 55px;">
                  <p class="mt-4">Value {{ value_selected }} AED</p>
                </div> -->
            </div>
            <div   class="info-box  justify-content-center  border border-secondary mb-2 col-md-10 ml-3 scroll" style="max-height: 450px;min-height: 450px;">
              <img src="../assets/Buyback_content/2 - Choose Brands & Product.svg" class="col-md-12"/>
              <b-row   class="d-flex justify-content-center" >
              <b-col v-for = "i in brandList" :key="i.id" class="mt-2" v-if="brand_selected"> 
                <a href="#">
                  <div class="info-box border border-secondary mb-2" style="max-height: 150px;max-width: 150px;min-height: 150px;min-width: 150px" @click="brand(i.id,i.name)">
                    <img :src="'data:image/png;base64,' + i.image" height="120" />
                    <br/>
                    {{ i.name }}
                </div>
                </a>
              </b-col>
            </b-row>
              <!-- <b-row class="d-flex justify-content-center" v-if="model_selected">
              <b-col v-for = "i in modelList2" :key="i.id" class="mt-2"> 
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center" style="min-width: 180px"  @click="model_new(i.id,i.name)">
                   
                    {{ i.name }} Series
                </div>
                </a>
              </b-col>
            </b-row> -->

            <b-row class="d-flex justify-content-center" v-if="model_selected">
              <b-col v-for = "i in modelList2" :key="i.id" class="mt-2"> 
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center" style="min-width: 180px" @click="model_new(i.id,i.name)">
                    <p class="txt2">{{ i.name }} Series</p> 
                </div>
                </a>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center" v-if="pro_selected">
              <b-col v-for = "i in modelPro" :key="i.id" class="mt-2"> 
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center" style="min-width: 180px" @click="pro_new(i.id,i.name)">
                    <p class="txt2">{{ i.name }}</p> 
                </div>
                </a>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center" v-if="repair">
              <b-col v-for = "i in priceList" :key="i.id" class="mt-2"> 
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center" style="min-width: 180px" @click="grades(i.id,i.name)">
                    <p class="txt2">{{ i.name }} inches</p> 
                </div>
                </a>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center" v-if="grade_selected2">
              <b-col v-for = "i in extraList" :key="i.id" class="mt-2"> 
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center" style="min-width: 180px" @click="grades2(i.id,i.name)">
                    <p class="txt2">{{ i.name }}</p> 
                </div>
                </a>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center" v-if="grade_selected3">
              <b-col v-for = "i in extraList2" :key="i.id" class="mt-2"> 
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center" style="min-width: 180px" @click="grades3(i.id,i.name)">
                    <p class="txt2">{{ i.name }}</p> 
                </div>
                </a>
              </b-col>
            </b-row>
            <b-row class=" d-flex justify-content-center" v-if="grade_selected">
            
              <b-col v-for = "i in gradeList" :key="i.id" class="" > 
                  <b-row style="flex-wrap: inherit !important;">
                    <b-col>
                      <div class="info-box3 txt border border-secondary " style="max-height: 320px;max-width: 250px;min-height: 320px;min-width: 250px"  v-if="i.a" v-b-modal.modal-prevent-closing @click="checkout_grade('A',i.a,i.vendor)">
                      <h4 class="mt-3">Condition 1</h4>
                      <h6>{{ i.a }} AED</h6>
                      <ul class="text-left mt-3 ml-4 mr-2" style="padding:0% ; ">
                        <li>
                          No Scratches
  
  
                        </li>
                        <li>
                          Body has minor scratches,  No dents
  
  
                        </li>
                        <li>
                          Battery health 90% or Above
  
  
                        </li>
                        <li>
                          Fully functional
  
  
                        </li>
                        <li>
                          No Device Lock/Sim Lock
  
  
                        </li>
                        <li>
                          No missing parts (back cover, sim tray, buttons)
  
  
                        </li>
                        <li>
                          Face ID working
  
  
                        </li>
                      </ul>
                    </div>
                    </b-col>
                    <b-col>
                      <div class="info-box3 txt border border-secondary" style="max-height: 320px;max-width: 250px;min-height: 320px;min-width: 250px" v-if="i.b" v-b-modal.modal-prevent-closing @click="checkout_grade('B',i.b,i.vendor)">
                      <h4 class="mt-3">Condition 2</h4>
                      <h6>{{ i.b }} AED</h6>
                      <ul class="text-left mt-3 mr-2  ml-4" style="padding:0% ;">
                        <li>
                          Screen having Scratches, open or swapped
  
  
                        </li>
                        <li>
                          Major scratches / dents on back cover and housing.
  
  
                        </li>
                        <li>
                          Battery health 80% or Above
  
  
                        </li>
                        <li>
                          Issues with either mic, speaker or buttons
  
  
                        </li>
                        <li>
                          No Device Lock/Sim Lock
  
  
                        </li>
                        <li>
                          No missing parts (back cover, sim tray, buttons)
  
  
                        </li>
                        <li>
                          Face ID working
  
  
                        </li>
                        
                      </ul>
                    </div>
                    </b-col>
                    <b-col>
                      <div class="info-box3 txt border border-secondary" style="max-height: 320px;max-width: 250px;min-height: 320px;min-width: 250px"   v-if="i.c" v-b-modal.modal-prevent-closing @click="checkout_grade('C',i.c,i.vendor)">
                      <h4  class="mt-3">Condition 3</h4>
                      <h6>{{ i.c }} AED</h6>
                      <ul class="text-left mt-2 ml-4 mr-2" style="padding:0% ;">
                        <li>
                          LCD dots/ lines/ discoloration/Screen Burn, Screen Touch not working
  
  
  
                        </li>
                        <li>
                          Device cracked / Bent device 
  
  
                        </li>
                        <li>
                          Battery health: Service indicator on
  
  
                        </li>
                        <li>
                          Any one of the following not working (touch ID, face ID, camera)
  
  
                        </li>
                        <li>
                          Device Lock/Sim Lock
  
                        </li>
                        <li>
                          Missing parts (back cover, sim tray or buttons)
  
  
                        </li>
                        <li>
                          Face ID not working
  
  
                        </li>
                      </ul>
                    </div>
                    </b-col>
                  </b-row>
  
              </b-col>
  
              
            </b-row>
            
            </div>
            <div>
              <b-modal
                id="modal-prevent-closing"
                ref="modal"
                scrollable
                title="Buyback"
                @show="resetModal"
                @hidden="resetModal"
                hide-footer
                @ok="handleOk"
              >
              <div class="p-2 " >
                <p class="" style="font-size: small;">Enter The Quantity</p>
                <b-form-input
                  type="text"
                  onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                  max="999999999"
                  placeholder="Enter The Quantity"
                  :state="mobileState"
                  v-model="userMobile"
                  class="input-login border-top-0 border-left-0 border-right-0"
                  :formatter="mobileFormatter"
                  ></b-form-input>
              </div>
              <div class="d-flex justify-content-center mt-3">
                <b-button @click="Buyback_cre()">Confirm</b-button>
              </div>
              
          </b-modal>
          <b-modal
              id="modal-prevent-closing-otp"
              ref="modal"
              title="Enter The OTP"
              @show="resetModal"
              @hidden="resetModal"
              @ok="handleOk2"
              hide-footer
  
            >
  
            <div class="d-flex justify-content-center">
              <otp-input
              :isValid="isCodeValid"
              :digits="4"
              @on-complete="onCompleteHandler"
              @on-changed="onChangedHandler"
              @on-paste="onPasteHandler">
                <template #errorMessage> There is an error </template>
              </otp-input>
            </div>
            <div class="d-flex justify-content-center">
              <b-button class="mt-3 btnn pl-4 pr-4"  @click="handleOk2()">Confirm</b-button>
  
            </div>
            </b-modal>
              <b-modal
                id="modal-prevent-closing2"
                ref="modal"
                scrollable
                title="Checkout"
                @show="resetModal"
                @hidden="resetModal"
                hide-footer
                @ok="handleOk"
              >
              <div class="d-flex justify-content-around mt-3">
                <b-button @click="Finish_Buyback()">Finish Buyback</b-button>
                <b-button @click="Add_Buyback()">Add an other Product</b-button>
              </div>
             
    </b-modal>
    <b-modal
        id="modalVerification"
        centered
        title=""
        footer-class="border-0 mx-auto"
        header-class="border-0"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        :hide-header-close="true"
      >
        <template #modal-header="{}">
        </template>
        <b-col class="wraper">
          <div  class="register-wraper">
            <div  class="otp-verification">
              <div class="thanks-wrap">
                <p class="otp-title">OTP Verification</p>
              </div>
              <p class="thanks-text mt-4">Enter the OTP sent to <span class="text-dark-grey">{{maskedMobile}}</span></p>
              <b-form inline class="justify-content-center add_space">
                <b-form-input
                  onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                  v-model="otp1"
                  ref="otpInput1"
                  data-index="1"
                  type="text"
                  max="1"
                  min="0"
                  inputmode="numeric"
                  :formatter="otpFormatter"
                  class="col-auto m-0 p-0 otp-inputnew"
                  @input="handleOTPInput1($event)"
                ></b-form-input>
                <b-form-input
                  onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                  type="text"
                  ref="otpInput2"
                  data-index="2"
                  max="1"
                  min="0"
                  inputmode="numeric"
                  v-model="otp2"
                  :formatter="otpFormatter"
                  class="col-auto m-0 p-0 otp-inputnew"
                  @input="handleOTPInput2($event)"
                ></b-form-input>
                <b-form-input
                  onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                  v-model="otp3"
                  :formatter="otpFormatter"
                  type="text"
                  max="1"
                  min="0"
                  inputmode="numeric"
                  ref="otpInput3"
                  data-index="3"
                  class="col-auto m-0 p-0 otp-inputnew"
                  @input="handleOTPInput3($event)"
                ></b-form-input>
                <b-form-input
                  onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                  v-model="otp4"
                  :formatter="otpFormatter"
                  type="text"
                  max="1"
                  min="0"
                  inputmode="numeric"
                  ref="otpInput4"
                  data-index="4"
                  class="col-auto m-0 p-0 otp-inputnew"
                ></b-form-input>
              </b-form>
              <p class="text-danger text-center">{{otpVerifyText}}</p>
              <div style="max-width: 210px; margin: 0 auto">
                <!-- <p class="resend-otp-text">Don't receive the OTP? <b-link @click="getOTP()" class="link">Resend OTP</b-link></p> -->
                <b-button squared block variant="success" class="mb-2" @click="processOrder()"
                >Verify & Proceed</b-button>
              </div>
            </div>
          </div>
        </b-col>
        <template #modal-footer>
          <div></div>
        </template>
      </b-modal>
          </div>
          
          </b-container>
          
        </div>
        
       </div>
        <div class="d-lg-none">
          <hr/>
          <div v-if="brand_selected"  class=" justify-content-center  " >
              <img src="../assets/Buyback_content/mobile/Step 1.svg" class="col-md-12"/>
  
              <b-row class="d-flex justify-content-center" >
              <b-col v-for = "i in brandList[0]" :key="i.id" class="mt-2"> 
                <a href="#">
                  <div class="info-box border border-secondary mb-2" style="max-height: 150px;max-width: 130px;min-height: 150px;min-width: 130px" @click="brand(i.id,i.name)">
                    <img :src="'data:image/png;base64,' + i.image" height="90" class="mt-1" />
                    <br/>
                    {{ i.name }}
                </div>
                </a>
              </b-col>
            </b-row>
            </div>
            <div v-if="model_selected || pro_selected || repair"  class=" justify-content-center  mb-5" >
              <img src="../assets/Buyback_content/mobile/Step 2.svg" class="col-md-12"/>
            <b-row class="d-flex justify-content-center" v-if="model_selected">
              <b-col v-for = "i in modelList2" :key="i.id" class="mt-2"> 
                <a href="#">
                  <div class="info-box border border-secondary mb-2" style="max-height: 150px;max-width: 130px;min-height: 150px;min-width: 130px" @click="model_new(i.id,i.name)">
                    <img :src="'data:image/png;base64,' + i.image" height="120" />
                    <br/>
                    {{ i.name }}
                </div>
                </a>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center" v-if="pro_selected">
              <b-col v-for = "i in modelPro" :key="i.id" class="mt-2"> 
                <a href="#">
                  <div class="info-box2 border border-secondary mb-2" style="min-height: 80px;min-width: 150px" @click="pro_new(i.id,i.name)">
                    <p  class="mt-4">{{ i.name }}</p> 
                </div>
                </a>
              </b-col>
            </b-row>
            
            <div class=" d-flex justify-content-center" v-if="repair">
              <b-col v-for = "i in priceList" :key="i.id" class="" v-if="brand_active === 'Mobiles'"> 
                  <div style="flex-wrap: inherit !important;">
                    <b-col>
                      <div class="info-box3 txt border border-secondary " style="max-height: 300px;max-width: 250px;min-height: 300px;min-width: 250px"  v-if="i.a" v-b-modal.modal-prevent-closing @click="checkout_grade('A',i.a,i.vendor)">
                      <h4 class="mt-3">Condition 1</h4>
                      <h6>{{ i.a }} AED</h6>
                      <ul class="text-left mt-3 ml-4 mr-2" style="padding:0% ; ">
                        <li>
                          No Scratches
                        </li>
                        <li>
                          Body has minor scratches,  No dents
                        </li>
                        <li>
                          Battery health 90% or Above
                        </li>
                        <li>
                          Fully functional
                        </li>
                        <li>
                          No Device Lock/Sim Lock
                        </li>
                        <li>
                          No missing parts (back cover, sim tray, buttons)
                        </li>
                      </ul>
                    </div>
                    </b-col>
                    <b-col class="mt-2">
                      <div class="info-box3 txt border border-secondary" style="max-height: 300px;max-width: 250px;min-height: 300px;min-width: 250px" v-if="i.b" v-b-modal.modal-prevent-closing @click="checkout_grade('B',i.b,i.vendor)">
                      <h4 class="mt-3">Condition 2</h4>
                      <h6>{{ i.b }} AED</h6>
                      <ul class="text-left mt-3 mr-2  ml-4" style="padding:0% ;">
                        <li>
                          Screen having Scratches, open or swapped
                        </li>
                        <li>
                          Major scratches / dents on back cover and housing.
                        </li>
                        <li>
                          Battery health 80% or Above
                        </li>
                        <li>
                          Issues with either mic, speaker or buttons
                        </li>
                        <li>
                          No Device Lock/Sim Lock
                        </li>
                        <li>
                          No missing parts (back cover, sim tray, buttons)
                        </li>
                      </ul>
                    </div>
                    </b-col>
                    <b-col class="mt-2">
                      <div class="info-box3 txt border border-secondary" style="max-height: 300px;max-width: 250px;min-height: 300px;min-width: 250px"   v-if="i.c" v-b-modal.modal-prevent-closing @click="checkout_grade('C',i.c,i.vendor)">
                      <h4  class="mt-3">Condition 3</h4>
                      <h6>{{ i.c }} AED</h6>
                      <ul class="text-left mt-2 ml-4 mr-2" style="padding:0% ;">
                        <li>
                          LCD dots/ lines/ discoloration/Screen Burn, Screen Touch not working
                        </li>
                        <li>
                          Device cracked / Bent device 
                        </li>
                        <li>
                          Battery health: Service indicator on
                        </li>
                        <li>
                          Any one of the following not working (touch ID, face ID, camera)
                        </li>
                        <li>
                          Device Lock/Sim Lock
                        </li>
                        <li>
                          Missing parts (back cover, sim tray or buttons)
                        </li>
                      </ul>
                    </div>
                    </b-col>
                  </div>
  
              </b-col>
            </div>
            
            </div>
        </div>
      </div>
    </template>
    
    <script>
    import api from '../api'
    import Header from '../components/Header.vue'
    import VueUploadMultipleImage from '../components/VueUploadMultipleImage.vue'
    import VueUploadMultipleImage2 from '../components/VueUploadMultipleImage2.vue'
      import  OtpInput  from  "otp-input-vue2";
    import { BIconChevronUp, BIconChevronDown } from 'bootstrap-vue'
    export default {
      name: 'LeftBar',
      props: [],
      components: {
        Header,
        VueUploadMultipleImage,
        VueUploadMultipleImage2,
        OtpInput,
        BIconChevronUp,
        BIconChevronDown
      },
      data () {
        return {
          promo:null,
          name2: '',
          id:null,
          id2: this.$route.params.id,

          name2State: null,
          status: 'not_accepted',
          selected: [], // Must be an array reference!
          options: [
            { text: 'Quality and Eligibility', value: 'Quality' },
            { text: 'Sim Card Removel', value: 'Sim' },
            { text: 'Data Wiped', value: 'Data' },
            { text: 'Find My Device', value: 'Find' }
          ],
          selected2: null,
          selected3: null,
          options2: [
            { value: null, text: 'New Device Model.' },
            { value: 'a', text: 'iPhone 13' },
            { value: 'b', text: 'iPhone 13 Pro' },
            { value: 'c', text: 'iPhone 13 Pro Max' },
          ],
          options3: [
            { value: null, text: 'Select Payment' },
            { value: 'a', text: 'Cash' },
            { value: 'b', text: 'Card' }
          ],
          sms_id:null,
          buyback_value: null,

          C:null,
          grade_selected: null,
          value_selected: null,
          brand_selected: true,
          brand_active: null,
          brand_id:null,
          model_selected: false,
          model_active: null,
          model_id:null,
          pro_selected: false,
          pro_active:null,
          pro_id:null,
          grade_selected: false,
          grade_active:null,
          grade_id:null,
          grade_selected2: false,
          grade_active2:null,
          grade_id2:null,
          grade_selected3: false,
          grade_active3:null,
          grade_id3:null,
          vendor:null,
          userName: this.$store.state.signUpForm ? this.$store.state.signUpForm.name : '',
          useradd: this.$store.state.signUpForm ? this.$store.state.signUpForm.useradd : '',
          userEmail: this.$store.state.signUpForm ? this.$store.state.signUpForm.email : '',
          userMobile: this.$store.state.signUpForm ? this.$store.state.signUpForm.mobile : '',
          text5: this.$store.state.signUpForm ? this.$store.state.signUpForm.text5 : '',
        }
      },
      computed: {
        nameState() {
          return this.userName.length > 3 ? true : false
        },
        addState() {
          return this.useradd.length > 6 ? true : false
        },
        emailState() {
          if (this.userEmail.length < 5 || this.userEmail.length) {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return re.test(String(this.userEmail).toLowerCase())
        } else {
          return null
        }
        },
        
        mobileState: function () {
        if (this.userMobile.length < 9 || this.userMobile.length) {
          return this.userMobile.length === 9
        } else {
          return null
        }
      },
        imeiState() {
          if (this.text5.length < 5 || this.text5.length) {
          const re = /^[0-9a-zA-Z]+$/
          return re.test(String(this.text5).toLowerCase())
        } else {
          return null
        }
        },
        brandList: function () {
          return this.$store.state.brandList
        },
        modelList: function () {
          return this.$store.state.modelList
        },
        modelList2: function () {
  
          return this.$store.state.modelList2
        },
        modelPro: function () {
          return this.$store.state.modelPro
        },
        modelGen: function () {
          return this.$store.state.modelGen
        },
        modelRam: function () {
          return this.$store.state.modelRam
        },
        romList: function () {
          return this.$store.state.romList
        },
        priceList: function () {
          return this.$store.state.deductionData
        },
        extraList: function () {
          return this.$store.state.extra
        },
        extraList2: function () {
          return this.$store.state.extra2
        },
        gradeList: function () {
          return this.$store.state.gradesData
        },
        defects: function () {
          return this.$store.state.defectList
        }
      },
      mounted () {
        this.$store.commit('CLEAR_SELECTION')
      },
      
      methods: {
        Add_Buyback() {
            this.$router.push({ name: 'LandingPage', params: { 'id': this.buyback_value } })

          },
          Finish_Buyback() {
            this.$router.push({ name: 'SellOrders', params: { 'id': this.buyback_value }})
          },
        Buyback_cre: function () {
          const inputs = {
            'inputs': {
              'session_id': this.$store.state.token,
              'model': 'cor.buyback',
              'method': 'buyback_creation',
              'args': {
                'id':this.$store.state.user.uid,
                'device':this.id,
                'qty': this.userMobile,
                'value':this.value_selected,
                'grade':this.grade_selected,
                'amount':this.value_selected,
                'batch':this.id2
              }
            },
            'modecode': 100
          }
          api
            .request('post', 'update', inputs)
            .then((response) => {
              var data = response.data
              if (data.errorcode) {
                // console.log(data.errormessage)
              }
              if (data.result) {

                this.buyback_value = data.result
                this.$bvModal.hide('modal-prevent-closing')
                this.$bvModal.show('modal-prevent-closing2')
                // this.$store.commit('SET_RECIEPT', data.result)
                // this.$router.push({ name: 'SellOrders', params: { 'id': data.result } })
                // this.$router.push({ name: 'Thanks', params: { 'id': data.result } })

                // console.log(data.result)
              }
            })
            .catch((error) => {
              // console.log('What happened? ' + error)
            })
          },
        getPromo: function (id) {
          const inputs = {
            'inputs': {
              'session_id': this.$store.state.token,
              'model': 'r.promo',
              'method': 'get_promotion_buyback',
              'args': {
                'id':this.$store.state.user.uid
              }
            },
            'modecode': 100
          }
          api
            .request('post', 'update', inputs)
            .then((response) => {
              var data = response.data
              if (data.errorcode) {
                return
              }
              if (data.result) {
                console.log(data.result)
                this.promo = data.result
              }
            })
            .catch((error) => {
              // console.log('What happened? ' + error)
            })
        },
        uploadImageSuccess(formData, index, fileList) {
        
      },
      myFunction(value) {
          // console.log(value)
       },
      beforeRemove (index, done, fileList) {
        // console.log('index', index, fileList)
        var r = confirm("remove image")
        if (r == true) {
          done()
        } else {
        }
      },
      editImage (formData, index, fileList) {
        // console.log('edit data', formData, index, fileList)
      },
        countDownChanged(dismissCountDown) {
          this.dismissCountDown = dismissCountDown
        },
        showAlert() {
          this.dismissCountDown = this.dismissSecs
        },
        mobileFormatter: function (e) {
        return String(e).substring(0, 9)
        },
        imeiFormatter: function (e) {
        return String(e).substring(0, 20)
        },
        checkout_grade: function (grade,price,vendor) {
          this.grade_selected = grade
          this.value_selected = price
          this.vendor = vendor
        },
        cate: function (cate_id, name) {
          this.category_selected = false
          this.category_active = name
          this.category_id = cate_id
          this.getBrands2(cate_id)
        },
        brand: function (cate_id, name) {
          this.brand_active = name
          this.brand_id = cate_id
          this.getModels(name)
          
        },
        model_new: function (cate_id, name) {
          this.model_active = name
          this.model_id = cate_id
          this.getYear(name)
        },
        pro_new: function (cate_id, name) {
          this.pro_active = name
          this.pro_id = cate_id
          this.getDeductionData(name)
        },
        grades: function (cate_id, name) {
          this.grade_active = name
          this.grade_id = cate_id
          this.getCellData(name)
        },
        grades2: function (cate_id, name) {
          this.grade_active2 = name
          this.grade_id2 = cate_id
          this.getCellData2(name)
        },
        grades3: function (cate_id, name) {
          this.grade_active3 = name
          this.grade_id3 = cate_id
          this.getGradeData(cate_id)
        },
        getModels: function (id) {
          const inputs = {
            'inputs': {
              'session_id': this.$store.state.token,
              'model': 'r5.records',
              'method': 'get_series_data',
              'args': {
                'cate': 'Tablets',
                'brand': this.brand_active
              }
            },
            'modecode': 100
          }
          api
            .request('post', 'update', inputs)
            .then((response) => {
              var data = response.data
              if (data.errorcode) {
                return
              }
              if (data.result) {
                this.$store.commit('SET_MODEL_LIST2', data.result)
                this.brand_selected = false
                this.model_selected = true
              }
            })
            .catch((error) => {
              // console.log('What happened? ' + error)
            })
        },
        getYear: function (id) {
          const inputs = {
            'inputs': {
              'session_id': this.$store.state.token,
              'model': 'r5.records',
              'method': 'get_model_data',
              'args': {
                'cate': 'Tablets',
                'brand': this.brand_active,
                'series':id
                
              }
            },
            'modecode': 100
          }
          api
            .request('post', 'update', inputs)
            .then((response) => {
              var data = response.data
              if (data.errorcode) {
                return
              }
              if (data.result) {
                this.$store.commit('SET_MODEL_Proc', data.result)
                this.model_selected = false
                this.pro_selected = true
              }
            })
            .catch((error) => {
              // console.log('What happened? ' + error)
            })
        },
        getDeductionData: function (id) {
          const inputs = {
            'inputs': {
              'session_id': this.$store.state.token,
              'model': 'r5.records',
              'method': 'get_rom_data',
              'args': {
                'cate': 'Tablets',
                'brand': this.brand_active,
                'series': this.model_active,
                'model':id
              }
            },
            'modecode': 100
          }
          api
            .request('post', 'update', inputs)
            .then((response) => {
              var data = response.data
              if (data.errorcode) {
                return
              }
              if (data.result) {
                // console.log('sdsds')
                this.$store.commit('SET_DEDUCTION_DATA', data.result)
                this.pro_selected = false
                this.repair = true
  
              }
            })
            .catch((error) => {
              // console.log('What happened? ' + error)
            })
        },
        getCellData: function (id) {
          const inputs = {
            'inputs': {
              'session_id': this.$store.state.token,
              'model': 'r5.records',
              'method': 'get_rom_data',
              'args': {
                'cate': 'Tablets',
                'brand': this.brand_active,
                'series': this.model_active,
                'model':this.pro_active,
                'screen':id
              }
            },
            'modecode': 100
          }
          api
            .request('post', 'update', inputs)
            .then((response) => {
              var data = response.data
              if (data.errorcode) {
                return
              }
              if (data.result) {
                // console.log('sdsds')
                this.$store.commit('SET_Extra_LIST', data.result)
                
                this.repair = false
                this.grade_selected2 = true
  
              }
            })
            .catch((error) => {
              // console.log('What happened? ' + error)
            })
        },
        getCellData2: function (id) {
          const inputs = {
            'inputs': {
              'session_id': this.$store.state.token,
              'model': 'r5.records',
              'method': 'get_rom_data',
              'args': {
                'cate': 'Tablets',
                'brand': this.brand_active,
                'series': this.model_active,
                'model':this.pro_active,
                'screen':this.grade_active,
                'cell':id
              }
            },
            'modecode': 100
          }
          api
            .request('post', 'update', inputs)
            .then((response) => {
              var data = response.data
              if (data.errorcode) {
                return
              }
              if (data.result) {
                // console.log('sdsds')
                this.$store.commit('SET_Extra_LIST2', data.result)
                
                this.grade_selected2 = false
                this.grade_selected3 = true
  
              }
            })
            .catch((error) => {
              // console.log('What happened? ' + error)
            })
        },
        
        getGradeData: function (id) {
          const inputs = {
            'inputs': {
              'session_id': this.$store.state.token,
              'model': 'r5.records',
              'method': 'get_price_data',
              'args': {
                'id':id,
                'location': this.$store.state.user.uid

              }
            },
            'modecode': 100
          }
          api
            .request('post', 'update', inputs)
            .then((response) => {
              var data = response.data
              if (data.errorcode) {
                return
              }
              if (data.result) {
                // console.log('sdsds')
                this.$store.commit('SET_GRADES_DATA', data.result)
                this.id = data.result[0].id

                this.grade_selected3 = false
                this.grade_selected = true
  
              }
            })
            .catch((error) => {
              // console.log('What happened? ' + error)
            })
        },
        
        getBrands2: function () {
          // if (!this.$store.state.selectedCategory) {
          //   return false
          // }
          const inputs = {
            'inputs': {
              'session_id': this.$store.state.token,
              'model': 'r4.records',
              'method': 'get_brand_data',
              'args': {
                'cate_id': 2
              }
            },
            'modecode': 100
          }
          const url = 'dataset'
          api
            .request('post', 'update', inputs)
            .then((response) => {
              var data = response.data
              if (data.errorcode) {
                // this.response = data.errormessage
                return
              }
              if (data.result) {
                this.$store.commit('SET_BRAND_LIST', data.result)
              }
            })
            .catch((error) => {
              // console.log('What happened? ' + error)
            })
        },
        clearSelection: function () {
          this.$store.commit('CLEAR_SELECTION')
        },
        visit: function (sel) {
          if ( sel === 'cate' ) {
            this.$router.push({ name: 'ReuseSell'})
          } else {
            window.location.reload()
          }
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
          },
          resetModal() {
            this.name = ''
            this.nameState = null
          },
          handleOk(bvModalEvent) {
            this.getOTP()
          },
          handleOk2(bvModalEvent) {
            this.$bvModal.hide('modal-prevent-closing-otp')
            this.$bvModal.show('modal-prevent-closing2')
            // this.getOTP()
          },
          handleOnComplete(value) {
              // console.log('OTP completed: ', value);
            },
            handleOnChange(value) {
              // console.log('OTP changed: ', value);
            },
            handleClearInput() {
              this.$refs.otpInput.clearInput();
            },
          handleSubmit() {
            if (!this.checkFormValidity()) {
              return
            }
            this.submittedNames.push(this.name)
            this.$nextTick(() => {
              this.$bvModal.hide('modal-prevent-closing')
            })
          },
       
      },
      mounted () {
      // this.$bvModal.show('modal-prevent-closing2')
  
      if (!this.$store.state.user) {
      this.$bvModal.show('modalSignIn')
      } else {
        this.getBrands2()
        this.getPromo()
  
      }
    }
    }
    </script>
    
    <style scoped>
      .icon-left {
      position: absolute;
      right: -20px;
      top: 0;
    }
    
    a {
      color: #A9A9A9 !important;
    }
    
    /* .selection-box-shadow {
      animation: mymove 3s infinite;
    } */
    
    @keyframes mymove {
      50% {
        box-shadow: 0px 0px 15px 0px rgba(0, 193, 56, 0.75);
        -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 193, 56, 0.75);
        -moz-box-shadow: 0px 0px 15px 0px rgba(0, 193, 56, 0.75);
      }
    }
    .aa{
      text-align: left;
      margin-left: 20px;
      padding-left: 5px;
      padding-right: 5px;
      margin-top: -10px;
      background: #FFFFFF;
      width: fit-content;
      position: absolute;
      display: block;
      z-index: 2;
      border-radius: 5px;
    }
    
    .details{
    padding: 20px;
    margin: 10px;
    background: #FFFFFF;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 7px;
    }
    .details2{
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 7px;
    }
    .details2 b{
      margin-left: 20px;
    }
    .list-group-item{
      color: black !important;
    }
    .list-group-item.active {
      background-color: rgb(56, 173, 56) !important;
      border-color: rgb(56, 173, 56) !important;
    }
    .info-box{
      margin-right: 3px;
      margin-left: 3px;
      padding: 3px;
      border-radius: 7px;
      border-color: #e0e0ec !important;
      text-align: center;
      background: #FFFFFF;
      border: 1px solid rgba(63, 63, 68, 0.005);
      box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
      border-radius: 7px;
    }
    .info-box4{
      margin-right: 3px;
      margin-left: 3px;
      padding: 3px;
      border-radius: 7px;
      border-color: #e0e0ec !important;
      text-align: center;
      background: #FFFFFF;
      border: 1px solid rgba(63, 63, 68, 0.005);
      box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
      border-radius: 7px;
    }
    .info-box4:hover {
    background-color: green;
    color: white;
  }
    .info-box2{
      margin-right: 3px;
      margin-left: 3px;
      padding: 3px;
      border-radius: 7px;
      border-color: green !important;
      text-align: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      
    }
    .info-box2:hover {
    background-color: green;
    color: white;
  }
  .info-box3{
      border-color: green !important;
      text-align: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }
    .info-box3:hover {
    background-color: green;
    color: white;
  }
    .otp-inputnew {
      width: 45px;
      height: 40px;
      padding: 5px;
      margin: 0 10px !important;
      font-size: 20px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-style: dashed;
      text-align: center;
      margin-right: 50px;
    }
    /* Background colour of an input field with value */
    .otp-inputnew.is-complete {
      background-color: #e4e4e4;
    }
    .otp-inputnew::-webkit-inner-spin-button,
    .otp-inputnew::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }
    .otp-title{
      color: #3FAF2B;
      font-weight: bold;
      font-size: 24px!important ;
    }
    .col {
    flex-grow: 0;
  }
  .scroll {
      overflow-x: hidden;
      overflow-y: auto;
      text-align:justify;
  }
  .txt{
    font-size: smaller;
  }
  .aa{
    text-align: left;
    margin-left: 10px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
    background: #FFFFFF;
    width: fit-content;
    position: absolute;
    display: block;
    font-size: smaller;
    font-weight: lighter;
    z-index: 2;
    border-radius: 5px;
  }
  .btnn:hover{
    background-color: #3FAF2B;
    border-color: #3FAF2B;
  }
  footer {
      position: fixed;
      bottom: 0px;
      width: 100%;
      background-color: #FFFFFF !important;
      border-radius: 15px 15px 0px 0px;
      justify-content: center;
      box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.5) !important;
  }
  .aaa{
    color: black !important;
  }
  .aaa:hover{
    color: #FFFFFF !important;
  }
  .a{
    color: black !important;;
  }
  .txt2{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
    </style>
    