<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                Redington Logo
              </h6>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <!-- <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>Or sign in with credentials</small>
            </div> -->
            <form>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  v-model="loginName"
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2  text-left"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  v-model="userPassword"
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                />
              </div>
              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    Remember me
                  </span>
                </label>
              </div>

              <div class="text-center mt-6">
                <button @click="signIn()"
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="button"
                >
                  Sign In
                </button>
                <button @click="signIn2()"
                  class="mt-4 bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="button"
                >
                  Partner Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import github from "@/assets/img/github.svg";
// import google from "@/assets/img/google.svg";
import api from "../../api"

export default {
  data() {
    return {
      loginName: null,
      userPassword: null
    };
  },
  computed: {
    signedUser: function () {
      return this.$store.state.user
    },
  },
  methods: {
    signIn2: function () {
      this.$router.push({ path: '/auth/partnerlogin'})

    },
    signIn: function () {
      const inputs = {
        
          'email': this.loginName,
          'password': this.userPassword, 
          'red': true,
          
      }
      console.log('Sign In input', inputs)
      api
        .request(process.env.VUE_APP_API_User_Signin, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            this.loginMessage = 'Invalid Credentials!'
            return
          }
          if (data.result) {
            if (data.result.uid) {
              this.$store.commit('SET_USER', data.result)
              localStorage.setItem('user_token',data.result.user_token)
              localStorage.setItem('token_data', JSON.stringify(data.result.token_data));
              setTimeout(() => this.$bvModal.hide('modalSignIn'), 10)
            }
            else {
              this.loginMessage = 'Invalid Credentials!'
              window.location.reload()

            }
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
  },
  mounted () {
    if (this.$store.state.user) {
      // this.$store.commit('CEAR_CART')
      // this.$store.commit('SET_USER', null)
      this.$router.push({ name: 'Admin'})
    } 
  }
};
</script>
