var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded",class:[_vm.color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']},[_c('div',{staticClass:"rounded-t mb-0 px-4 py-3 border-0"},[_c('div',{staticClass:"flex flex-wrap items-center"},[_c('div',{staticClass:"relative w-full px-4 max-w-full flex-grow flex-1"},[_c('h3',{staticClass:"font-semibold text-lg",class:[_vm.color === 'light' ? 'text-blueGray-700' : 'text-white']},[_vm._v(" Partner List ")])])])]),_c('div',{staticClass:"block w-full overflow-x-auto"},[_c('table',{staticClass:"items-center w-full bg-transparent border-collapse"},[_c('thead',[_c('tr',[_c('th',{staticClass:"px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
              _vm.color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]},[_vm._v(" Partner ID ")]),_c('th',{staticClass:"px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
              _vm.color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]},[_vm._v(" Partner ")]),_c('th',{staticClass:"px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
              _vm.color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]},[_vm._v(" Contact Person ")])])]),_c('tbody',_vm._l((_vm.data),function(i){return _c('tr',{key:i,on:{"click":function($event){return _vm.getBuyback(i.id)}}},[_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[_vm._v(" PB00"+_vm._s(i.id)+" ")]),_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[_vm._v(" "+_vm._s(i.com_name)+" ")]),_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[_vm._v(" "+_vm._s(i.name)+" ")])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }