import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
import LandingPage from "../layouts/Auth.vue";
import Login from "../views/auth/Login.vue";
import Login2 from "../views/auth/Login2.vue";
import Register from "../views/auth/Register.vue";
// views for Admin layout
import Admin from "../layouts/Admin.vue";

import Dashboard from "../views/admin/Dashboard.vue";
import Users from "../views/admin/Users.vue";
import Promotion from "../views/admin/Promo.vue";
import Settings from "../views/admin/Settings.vue";
import Tables from "../views/admin/Tables.vue";
import OverView from "../views/admin/Tables_ov.vue";
import Tables2 from "../views/admin/Tables2.vue";
import Tables4 from "../views/admin/Tables4.vue";
import Tables3 from "../views/admin/Tables3.vue";
import Tables5 from "../views/admin/Tables5.vue";

import Maps from "../views/admin/Maps.vue";

import Mobile from "../components/Mobiles.vue";
import Tablets from "../components/Tablets.vue";

import Laptop from "../components/Laptop.vue";
import Headphones from "../components/headphones.vue";
import Smartwatches from "../components/Smartwatches.vue";
import Orders from "../views/Orders.vue";
import Orders2 from "../views/Orders2.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ForgotPasswordEnter from "../views/ForgotPasswordEnter.vue";
import PasswordChanged from "../views/PasswordChanged.vue";
import store from "../store";
import api from "../api";
import PageNotFound from "../views/PageNotFound";
import FAQ from "../views/FAQ.vue";
import TrackBuyback from "../views/TrackBuyback.vue";
import TrackBuyback2 from "../views/TrackBuyback2.vue";
import Promotions from "../views/admin/Promotions.vue";
import Devices from "../views/admin/Devices.vue";
import GbbDetail from "../views/admin/GbbDetail.vue";

const getSession = (to, from, next) => {
  if (store.state.token === null) {
    const inputs = {
      inputs: {
        login: "admin",
        password: "admin",
      },
      modecode: 100,
    };
    const url = "admin";
    api
      .request("post", url, inputs, "login")
      .then((response) => {
        var data = response.data;
        if (data.error) {
          this.error = "Login Failed!";
          return;
        }
        if (data.result) {
          var userAuth = data.result.session_id;
          store.commit("SET_TOKEN", userAuth);
          next();
          if (window.localStorage) {
            window.localStorage.setItem("user", userAuth);
          }
        }
      })
      .catch(() => {
        alert("Something went wrong! Please try again later.");
      });
  } else {
    next();
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/auth/login",
    component: LandingPage,
    children: [
      {
        path: "/auth/login",
        component: Login,
      },
      {
        path: "/auth/partnerlogin",
        component: Login2,
      },
      {
        path: "/auth/register",
        component: Register,
      },
    ],
  },
  {
    path: "/admin",
    redirect: "/admin/batch",
    component: Admin,
    name: "Admin",
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard,
      },
      {
        path: "/admin/overview",
        component: OverView,
      },
      {
        path: "/admin/users",
        component: Users,
      },
      {
        path: "/admin/promo",
        component: Promotion,
      },
      {
        path: "/admin/settings",
        component: Settings,
      },
      {
        path: "/admin/batch",
        component: Tables,
      },
      {
        path: "/admin/batch/:id",
        component: Tables2,
      },
      {
        path: "/admin/buyback/:id",
        component: Tables3,
      },
      {
        path: "/admin/gbb/:id",
        component: GbbDetail,
      },
      {
        path: "/admin/maps",
        component: Maps,
      },
      {
        path: "/admin/report",
        component: Tables5,
      },
      {
        path: "/admin/promotions",
        component: Promotions,
      },
      {
        path: "/admin/devices",
        component: Devices,
      },
      {
        path: "/admin/maps/:id",
        component: Tables4,
      },
    ],
  },
  // {
  //   path: '/',
  //   name: 'LandingPage',
  //   component: LandingPage,
  //   meta: { facebookDomainVerification: 'h2trof9ymlvqyf8fd4k8yaabyf35h7' },
  //   children: [
  //   ]
  // },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { facebookDomainVerification: "h2trof9ymlvqyf8fd4k8yaabyf35h7" },
    children: [],
  },
  {
    path: "/forgotpasswordlink",
    name: "ForgotPasswordEnter",
    component: ForgotPasswordEnter,
    meta: { facebookDomainVerification: "h2trof9ymlvqyf8fd4k8yaabyf35h7" },
    children: [],
  },
  {
    path: "/passwordchanged",
    name: "PasswordChanged",
    component: PasswordChanged,
    meta: { facebookDomainVerification: "h2trof9ymlvqyf8fd4k8yaabyf35h7" },
    children: [],
  },
  // {
  //   path: '/about-sell-old-phone-buy-used-mobile-online-and-mobile-repair-dubai-uae',
  //   name: 'Home',
  //   component: Home,
  //   meta: { facebookDomainVerification: 'h2trof9ymlvqyf8fd4k8yaabyf35h7' },
  //   children: [
  //   ]
  // },

  {
    path: "/mobiles",
    name: "Mobile",
    component: Mobile,
  },
  {
    path: "/laptop",
    name: "Laptop",
    component: Laptop,
  },
  {
    path: "/smartwatches",
    name: "Smartwatches",
    component: Smartwatches,
  },
  {
    path: "/headphones",
    name: "Headphones",
    component: Headphones,
  },
  {
    path: "/tablets",
    name: "Tablets",
    component: Tablets,
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    props: true,
  },
  {
    path: "/buybacklist",
    name: "Buyback",
    component: Orders2,
    props: true,
  },
  {
    path: "/orders/sell/:id",
    name: "SellOrders",
    component: TrackBuyback,
    props: true,
  },
  {
    path: "/batch/:id",
    name: "SellOrders2",
    component: TrackBuyback2,
    props: true,
  },
  {
    path: "/faq",
    component: FAQ,
    name: "FAQ",
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

// router.beforeEach(getSession)

export default router;
