<template>
  <div>
    <div class="d-none d-lg-block">
      <SplashScreen v-if="this.$store.state.isLoading" />
      <b-navbar toggleable="lg" type="dark" class="py-0">
        <b-navbar-brand :to="{ name: 'LandingPage' }" class="py-0">
          <img src="../assets/Buyback_content/Redington (India).png" alt="Redington" />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <div>
              <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" class="my-2 ">
                <template #button-content>
                  <span style="font-size: medium;color: black;"><img src="../assets/country/UAE-01.svg" width="25px"/>
                    <b class="ml-2">UAE</b>
                  </span>
                </template>
                <b-dropdown-item><img src="../assets/country/UAE-01.svg" width="25px"/><b class="ml-2">UAE</b></b-dropdown-item>
                <b-dropdown-item><img src="../assets/country/Qatar-01.svg" width="25px"/><b class="ml-2">Qatar</b></b-dropdown-item>
                <b-dropdown-item><img src="../assets/country/Kenya-01.svg" width="25px"/><b class="ml-2">KENYA</b></b-dropdown-item>
                <b-dropdown-item><img src="../assets/country/Nigeria-01.svg" width="25px"/><b class="ml-2">Nigeria</b></b-dropdown-item>
                <b-dropdown-item><img src="../assets/country/KSA-01.svg" width="25px"/><b class="ml-2">KSA</b></b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="my-2 ml-3">
              <img src="../assets/SVG/Language Icon.svg" width="25px"/><b class=""></b>
              <b-button to="#"  style="background-color: transparent;border: none; color: black;">العربية</b-button>
            </div>
            <sign-in />
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <div class="d-lg-none ml-auto mt-2 mb-2 d-flex justify-content-between">
      <!-- <SplashScreen v-if="this.$store.state.isLoading" /> -->
        <b-navbar toggleable="sm"  style="justify-content:left !important">
          <!-- <b-navbar-toggle target="nav-text-collapse" style="border: none !important;">
            <img src="../assets/Buyback_content/Vector.svg" class="d-inline-block align-top" alt="Kitten">
            
          </b-navbar-toggle> -->
          
          <b-navbar-toggle style="border: none !important;"  class="d-flex">
            <b-navbar-brand :to="{ name: 'LandingPage' }" class="py-0">
              <img  src="../assets/Buyback_content/Redington (India).png" alt="Redington"  width="135px"  />
            </b-navbar-brand>
          </b-navbar-toggle>
         
          

          <b-collapse id="nav-text-collapse" is-nav style="width: 10px;">
            <b-navbar-nav>
              <div class="my-2 text-left" style="color: black !important;">
                  <b-button  style="color: black !important;" v-b-modal.modal-prevent-closing2 class="ml-3  text-decoration-none py-0" variant="link">Track Order</b-button>
                </div>
              <div class="my-2 text-left">
                <b-button to="/faq" class="ml-3  text-decoration-none py-0" variant="link">FAQs</b-button>
                </div>
                <div class="my-2 text-left">
                  <b-button to="/about-sell-old-phone-buy-used-mobile-online-and-mobile-repair-dubai-uae" class="ml-3  text-decoration-none py-0" variant="link">About</b-button>
                </div>
                <div class="my-2 text-left">
                  <b-button to="/contact" class="ml-3  text-decoration-none py-0" variant="link">Contact</b-button>
                </div>
            </b-navbar-nav>
          </b-collapse>

        </b-navbar>
        <div class="mt-2"  style="justify-content:right !important" v-if="!this.$store.state.user">
          <sign-in  style="list-style-type: none"/>
        </div>
        <div   style="justify-content:right !important" v-else>
          <sign-in  style="list-style-type: none"/>
        </div>

      <!-- <div class="my-2 mr-3">
        <b-button to="/reuse/sell-gadgets-used-iPad-second-hand-phones-in-dubai-uae" variant="success" style="color: white;max-height: 35px; ">Sell Now</b-button>
      </div>         -->
      <!-- <div class="h2 ml-3">
        <b-button to="/orders" class="ml-4 orders text-decoration-none py-0" variant="link">Orders</b-button>
        <b-button to="/cart" class="ml-4 cart text-decoration-none py-0" variant="link">Cart</b-button>
        <sign-in class="ccc ml-auto"/>
      </div> -->
    </div>
  </div>
</template>

<script>
import api from '../api'
import SignIn from '@/components/SignIn.vue'
import SearchBox from './SearchBox.vue'
import SplashScreen from '../components/SplashScreen.vue'
export default {
  name: 'Header',
  props: ['items'],
  components: {
    SearchBox,
    SignIn,
    SplashScreen,
    name: '',
    nameState: null,
    submittedNames: [],
    order: false
  },
  data () {
    return {
    selected: null
    }
  },
  methods: {
  },
  watch: {
    $route: function (to, from) {
      if (to.name === 'Home') {
        this.$store.commit('SET_SERVICE', null)
      }
    }
  }
}
</script>

<style>
.navbar-dark {
  box-shadow: 1px 0px 5px #444;
}
.cart {
  /* background-image: url('../assets/imgs/cart.png'); */
  background-repeat: no-repeat;
  background-size: 17px;
  padding-left: 24px;
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #333530;
  align-self: center;
}
.cart:hover {
  /* background-image: url('../assets/imgs/cart-clicked.png'); */
  color: #28a745;
}
.orders {
  /* background-image: url('../assets/imgs/orders.png'); */
  background-repeat: no-repeat;
  background-size: 17px;
  padding-left: 24px;
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #333530;
  align-self: center;
}
.orders:hover {
  /* background-image: url('../assets/imgs/orders-clicked.png'); */
  color: #28a745;
}
.ar {
  /* background-image: url('../assets/imgs/ar.png'); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  width: 90px;
  height: 20px;
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #333530;
}
.ar:hover {
  /* background-image: url('../assets/imgs/ar-clicked.png'); */
  color: #28a745;
}
.navbar-dark .navbar-toggler-icon {
  /* background-image: url('../assets/imgs/nav-collapse.svg'); */
}
#nav-collapse .navbar-nav {
  align-items: center;
}
button.navbar-toggler:focus {
  outline: none
}
.h1 {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.h2 {
  display: flex;
}
.ccc{
  list-style-type: none;
  color: black !important;
}
/* .btnn{
font-size:larger;
padding: 10px 20px 10px 20px;
font-weight: 800;
color: #39A536;
border-radius: 7px;
} */
.btnn:hover{
  background-color: #3FAF2B;
  border-color: #3FAF2B;
}
</style>
