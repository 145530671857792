<template>
  <div v-if="loader" class="spinner-container">
  <!-- Spinner loader -->
  <div class="spinner">
  </div>
</div>
    <div
    v-else class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
    >
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3
              class="font-semibold text-lg"
              :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
            >
              GBB Details
            </h3>
          </div>
        </div>
      </div>
      <div>
        <b-card no-body>
          <b-tabs  card vertical>
            <b-tab title="Product Details" active><b-card-text>
              <div class="block w-full overflow-x-auto">
                <table class="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr>
                      <th
                        class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        :class="[
                          color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                        ]"
                      >
                      Product Category
                      </th>
                      <th
                        class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        :class="[
                          color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                        ]"
                      >
                      Product Brand
                      </th>
                      <th
                        class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        :class="[
                          color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                        ]"
                      >
                      Product Name
                      </th>
                      
                      <th
                        class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        :class="[
                          color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                        ]"
                      >
                      IMEI / Serial No
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr >
                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          {{ data.product.category }}
                        </td>
                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          {{ data.product.brand }}
                        </td>
                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          {{ data.product.name }}
                        </td>
                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                          {{  data.product.imei }}
                        </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-card-text></b-tab>
            <b-tab title="Partner" style="padding: 0px !important;height: 10px !important;" ><b-card-text>
              <b-card no-body   style="padding: 0px !important; border: none; ">
                <b-tabs  align="center" style="padding: 0px !important; border: none !important;; ">
                  <b-tab title="Partner" ><b-card-text>
                    <div class="block w-full overflow-x-auto">
                      <table class="items-center w-full bg-transparent border-collapse">
                        <thead>
                          <tr>
                       
                            <th
                              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                              :class="[
                                color === 'light'
                                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                              ]"
                            >
                            Partner Name
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                           
                              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {{ data.partner }}
                              </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-card-text></b-tab>
                
                </b-tabs>
              </b-card>
            </b-card-text></b-tab>
      
            <b-tab title="Customer" style="padding: 0px !important;height: 10px !important;" ><b-card-text>
              <b-card no-body   style="padding: 0px !important; border: none; ">
                <b-tabs  align="center" style="padding: 0px !important; border: none !important;; ">
                  <b-tab title="Customer Details" ><b-card-text>
                    <div class="block w-full overflow-x-auto">
                      <table class="items-center w-full bg-transparent border-collapse">
                        <thead>
                          <tr>
                            <th
                              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                              :class="[
                                color === 'light'
                                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                              ]"
                            >
                            Customer ID
                            </th>
                            <th
                              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                              :class="[
                                color === 'light'
                                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                              ]"
                            >
                            Customer Name
                            </th>
                            <th
                              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                              :class="[
                                color === 'light'
                                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                              ]"
                            >
                            Customer Email
                            </th>
                            <th
                              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                              :class="[
                                color === 'light'
                                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                              ]"
                            >
                            Customer Number
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr >
                              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {{ data.customer_data.id }}
                              </td>
                              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {{data.customer_data.name  }}
                              </td>
                              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {{data.customer_data.email  }}
                              </td>
                              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {{data.customer_data.number  }}
                              </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-card-text></b-tab>
                 
                </b-tabs>
              </b-card>
            </b-card-text></b-tab>
            <b-tab title="Condition"><b-card-text>
              <div>
                <h5  class="px-6 align-middle  py-0 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">Device Condition</h5>

                    <div v-html="data.condition_description" class="condition-html mt-0 ml-2"></div>
               
              </div>
             
            </b-card-text></b-tab>
            <b-tab title="Product Images"><b-card-text>
              <div class="d-flex flex-column p-4 bg-dark container-image">
    <b-row v-for="row in imageRows" :key="row.id" class="mb-3">
      <b-col
        v-for="(image, index) in row"
        :key="index"
        class="d-flex justify-content-center align-items-center"
      >
        <b-img
          v-if="image"
          thumbnail
          fluid
          class="img-thumb"
          :src="'data:image/png;base64,' + image.file"
          :alt="'Image ' + (image.index + 1)"
          @click="showimg(image.name)"
        ></b-img>
        <b-modal :id="image.name" hide-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <img :src="'data:image/png;base64,' + image.file" width="350px" />
          </div>
        </b-modal>
      </b-col>
    </b-row>
  </div>
            </b-card-text></b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </template>
  <script>
  import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";
  
  import bootstrap from "@/assets/img/bootstrap.jpg";
  import angular from "@/assets/img/angular.jpg";
  import sketch from "@/assets/img/sketch.jpg";
  import react from "@/assets/img/react.jpg";
  import vue from "@/assets/img/react.jpg";
  
  import team1 from "@/assets/img/team-1-800x800.jpg";
  import team2 from "@/assets/img/team-2-800x800.jpg";
  import team3 from "@/assets/img/team-3-800x800.jpg";
  import team4 from "@/assets/img/team-4-470x470.png";
  import api from '../../api'
  
  export default {
    data() {
      return {
        id: this.$route.params.id,
        bootstrap,
        angular,
        sketch,
        react,
        vue,
        team1,
        team2,
        team3,
        team4,
        data:null,
        user: this.$store.state.user,
        loader: false
  
      };
    },
    components: {
      TableDropdown,
    },
    props: {
      color: {
        default: "light",
        validator: function (value) {
          // The value must match one of these strings
          return ["light", "dark"].indexOf(value) !== -1;
        },
      },
    },
    computed: {
      imageRows() {
      // Filter out undefined images and group them into rows of 2
      const images = (this.data.attachment || []).filter((img) => img && img.file);
      return images.reduce((rows, img, index) => {
        const rowIndex = Math.floor(index / 2);
        if (!rows[rowIndex]) rows[rowIndex] = [];
        rows[rowIndex].push({ ...img, index });
        return rows;
      }, []);
    },
    },
    methods: {
      // getBuyback: function (id) {
      //   this.$router.push({ path: '/admin/batch/'+ id, params: { 'id': id } })
      // },
      showimg: function (id) {
        this.$bvModal.show(id)
      },
      getDetails: function () {
        this.loader = true;
        var token_data = JSON.parse(localStorage.getItem('token_data'));
        const inputs = {
          
              'id': this.id,
              'user_token':localStorage.getItem('user_token'),
              'token_data':token_data,
            
        }
        api.request(process.env.VUE_APP_GET_GBB_DETAILS, inputs).then((response) => {
          const data = response.data;

          console.log('******************',response)
          if (data.errorcode) {
            this.errormessage = data.errormessage
            this.loader = false;
          } else if (data.result && typeof data.result.data[0] === 'object') {
            this.data = data.result.data[0]
            this.loader = false;
            console.log('Product',data.result)
          } else if (data.result && typeof data.result === 'string') {
            this.errormessage = data.result
            this.loader = false;
          }
        }).catch((error) => {
          this.errormessage = error
          this.loader = false;
        })
      }
    },
    mounted () {
        this.getDetails()
    }
  };
  </script>
  
  <style scoped>
  ul.a {
        list-style-type: circle;
      }
      .img-thumb {
  max-width: 100%;
  height: 200px; /* Ensure all images have consistent height */
  object-fit: cover; /* Crop images to fill the dimensions without distortion */
  cursor: pointer;
}
.container-image {
  gap: 10px;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #24c215;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

  </style>