import { render, staticRenderFns } from "./OrderList2.vue?vue&type=template&id=196c899d&scoped=true"
import script from "./OrderList2.vue?vue&type=script&lang=js"
export * from "./OrderList2.vue?vue&type=script&lang=js"
import style0 from "./OrderList2.vue?vue&type=style&index=0&id=196c899d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "196c899d",
  null
  
)

export default component.exports