<template>
  <div>
    <b-modal  @cancel="handleOk"  @ok="handleOk" v-model="modalShow">Something Went Wrong, Try again after sometime.</b-modal>
    <div class="container"> 
    <input :type="show === true ? 'text' : 'password'" id="password" v-model="password">
    <div class="strength" :class="'level_' + strengthLevel"></div>


    <i class="fas fa-eye show-icon" v-if="show" @click="show = !show"></i>
    <i class="fas fa-eye-slash hide-icon" v-else @click="show = !show"></i>
  
    <i class="fas fa-frown level-icon" v-if="strengthLevel === 1"></i>
    <i class="fas fa-meh level-icon" v-if="strengthLevel === 2"></i>
    <i class="fas fa-smile level-icon" v-if="strengthLevel === 3"></i>
    <i class="fas fa-grin-stars level-icon" v-if="strengthLevel === 4"></i>
    <!-- <div v-if="strengthLevel === 1">Strong</div> -->

  </div>
  
  <div class="mt-3 container">
      <h5 v-if="strengthLevel !== 0"><b>Strength</b>:</h5>
      <h5 v-if="strengthLevel === 1" style="color: red;"> Low</h5>
      <h5 v-if="strengthLevel === 2"  style="color: orange;"> Medium</h5>
      <h5 v-if="strengthLevel === 3"  style="color: #C4A484;"> High</h5>
      <h5 v-if="strengthLevel === 4"  style="color: green;"> Strong</h5>
  </div>
  <h6 style="color: red;" v-if="error">Something Went Wrong, Try Again</h6>
  <b-button v-if="strengthLevel <= 2" variant="success"  disabled style="font-size: larger;"  >Change Password</b-button>
  <b-button v-if="strengthLevel > 2" variant="success"   style="font-size: larger;"  @click="forgotpassword()">Change Password</b-button>

  </div>

</template>

<script>
  import api from '../api'
  export default {
    data: () => {
      return {
        password: '',
        show: false,
        error: false,
        search_id: '',
        sended: false,
        modalShow: false

      }
    },
    computed: {
      scorePassword() {
        let score = 0;
        if(this.password === '') return score;

        let letters = {};
        for(let i = 0; i < this.password.length; i++) {
          letters[this.password[i]] = (letters[this.password[i]] || 0) + 1;
          score += 5.0 / letters[this.password[i]];
        }

        let variations = {
          digits: /\d/.test(this.password),
          lower: /[a-z]/.test(this.password),
          upper: /[A-Z]/.test(this.password),
          special: /\W/.test(this.password)
        };

        let variationsCount = 0;
        for (let check in variations) {
          variationsCount += (variations[check] === true) ? 1 : 0;
        }

        score += (variationsCount - 1) * 10;

        return parseInt(score);
      },
      strengthLevel() {
        let pass = this.scorePassword;
        if(pass === 0) return 0;
        if(pass < 25) return 1;
        if(pass < 50) return 2;
        if(pass < 75) return 3;
        if(pass >= 75) return 4;
      }
    },
    methods: {
    forgotpassword: function () {
      this.search_id = window.location.href
      const tokken = this.search_id.split('?')[1]
      // console.log(tokken)
      // console.log(this.password)
        const inputs = {
            'inputs': {
            'session_id': this.$store.state.token,
            'model': 'res.users',
            'method': 'forgot_passwaord',
            'args': { 
                'barcode': tokken,'password': this.password
              }  
            },
            'modecode': 100
        }
        api.request('post', 'update', inputs)
          .then((response) => {
            // console.log('API Response:', response)
            var data = response.data
            // console.log('API Response Data:', data.result)
            if (data.errorcode) {
                this.response = data.errormessage
                return
            }
            if (data.result === false) {
              console.log('Asher')
              this.modalShow = true
            }
            if (data.result) {
              this.$router.push({ name: 'PasswordChanged' })
            }
            })
            .catch((error) => {
            console.log('What happened? ' + error)
            })
        },
        handleOk(bvModalEvent) {
          this.$router.push({ name: 'LandingPage' })

      },
    }
  }
</script>

<style lang="scss" scoped>
  @import url('https://use.fontawesome.com/releases/v5.7.2/css/all.css');

  .container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 300px;
    height: 50px;
  }

  input {
    position: relative;
    color: #333;
    font-size: 1.5rem;
    padding: 6px 35px 4px;
    border: none;
    border-radius: 40px;
    overflow: hidden;
    z-index: 10;
    border: 1px solid black;
    &:focus {
      outline: none;
    }
  }

  .strength {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    display: block;
    width: 25%;
    height: 100%;
    margin-left: 10px;
    background-color: #a5df41;
    border-radius: 40px;
    overflow: hidden;
    z-index: 9;
    transition: all .5s linear;
  }

  @mixin level($position, $width, $color) {
    bottom: $position;
    width: $width;
    background-color: $color;
  }

  .level_0 {
    @include level(-5, 25%, #BB4440);
  }
  .level_1 {
    @include level(-5px, 25%, #BB4440);
  }
  .level_2 {
    @include level(-5px, 50%, #E17D30);
  }
  .level_3 {
    @include level(-5px, 75%, #F0B03F);
  }
  .level_4 {
    @include level(-5px, 93%, #a5df41);
  }

  .level-icon {
    position: absolute;
    right: 20px;
    top:20px;
    z-index: 11;
  }

  @mixin showpass($property) {
    position: absolute;
    left: $property;
    top: 20px;
    z-index: 11;
    cursor: pointer;
  }

  .show-icon {
    @include showpass(20px);
  }

  .hide-icon {
    @include showpass(20px);
    color: #555;
  }
</style>
