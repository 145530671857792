<template>
    <div class="flex flex-wrap mt-4">
        <div class="w-full mb-12 px-4">
            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded" :class="['bg-white']">
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="d-flex justify-content-between relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-lg" :class="['text-blueGray-700']">
                                Devices
                            </h3>
                            <div class="d-flex filer__box">
                                <div class="input-group">
                                    <input type="text" class="form-control rounded"
                                        placeholder="Search device by name...." aria-label="Username"
                                        @input="searchData" v-model="searchTerm" aria-describedby="basic-addon1">
                                </div>
                                <b-button @click="openModal('add')">Add</b-button>
                                <!-- <b-button><i class="fas fa-filter"></i></b-button> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block w-full overflow-x-auto">
                    <!-- Spinner -->
                    <div v-if="isLoading" class="flex justify-center items-center py-8">
                        <div class="loader"></div>
                    </div>

                    <!-- Table -->
                    <table v-else class="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th
                                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Sr no.
                                </th>
                                <th
                                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Device ID
                                </th>
                                <th
                                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Category
                                </th>
                                <th
                                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Description
                                </th>
                                <th
                                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Brand
                                </th>
                                <th
                                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Edit
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in paginatedData" :key="index">
                                <td
                                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {{ index + 1 }}
                                </td>
                                <td
                                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {{ item.id }}
                                </td>
                                <td
                                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {{ item.category }}
                                </td>
                                <td
                                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {{ item.name }}
                                </td>
                                <td
                                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {{ item.brand }}
                                </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 cursor-pointer"
                                    @click="openModal('edit', item)">
                                    <i class="fas fa-pencil"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="flex justify-end m-4">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="my-table"></b-pagination>
                    </div>

                </div>
            </div>
        </div>

        <!-- Modal -->
        <b-modal v-model="showModal" :title="modalTitle" hide-footer>
            <form>
                <div class="mb-3">
                    <label for="category" class="form-label">category</label>
                    <v-select :options="categoryData" label="key" :reduce="cate => cate.value" v-model="form.category"
                        placeholder="Search for a device model..." required>
                    </v-select>
                    <span class="text-danger">{{ errorMessages.category }}</span>
                </div>
                <div class="mb-3 d-flex justify-content-between">
                    <div class="mb-3 mr-2">
                        <label for="brand" class="form-label">Brand </label>
                        <input type="text" class="form-control" id="brand" placeholder="eg:Apple(first letter capital)"
                            v-model="form.brand" required>
                        <span class="text-danger">{{ errorMessages.brand }}</span>
                    </div>
                </div>
                <div class="mb-3 d-flex justify-content-between">
                    <div class="mb-3 mr-2">
                        <label for="description" class="form-label">Description </label>
                        <input type="text" class="form-control" id="brand" placeholder="eg: iPhone 14 Pro Max 512 GB"
                            v-model="form.name" required>
                        <span class="text-danger">{{ errorMessages.name }}</span>
                    </div>
                </div>

            </form>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
                <button type="submit" class="btn btn-primary" @click="handleSubmit">Save</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from '../../api';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            rows: 0,
            deviceData: [],
            isLoading: false,
            showModal: false,
            categoryData: [
                {
                    key: 'Laptop',
                    value: 'Laptop'
                },
                {
                    key: 'Mobile',
                    value: 'Mobile'
                },
                {
                    key: 'Headphones & wireless audio',
                    value: 'Headphones & wireless audio'
                },
                {
                    key: 'Smart Watches',
                    value: 'Smartwatches'
                },
                {
                    key: 'Tablets',
                    value: 'Tablets'
                }
            ],
            modalTitle: '',
            filteredDeviceData: [],
            searchTerm: '',
            form: {
                category: null,
                brand: '',
                name: '',
                id: null
            },
            errorMessages: {
                category: '',
                brand: '',
                name: ''
            }

        };
    },
    components: {
        vSelect,
    },
    computed: {
        paginatedData() {

            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;

            this.rows = this.filteredDeviceData.length;
            return this.filteredDeviceData.slice(start, end);

        }
    },
    methods: {

        openModal(type, item = null) {
            this.showModal = true;
            if (type === 'add') {
                this.modalTitle = 'Add Device';

                this.form = { partner_name: '', promo: '', price: null };
            } else if (type === 'edit') {
                this.modalTitle = 'Edit Device';
                this.form = { ...item };
                console.log(this.form)


            }
        },
        handleSubmit() {
            if (this.validateForm()) {
                if (this.modalTitle === 'Add Device') {
                    this.deviceAction('add');

                    this.form = {
                        category: '',
                        brand: '',
                        name: '',
                    }

                } else if (this.modalTitle === 'Edit Device') {
                    this.deviceAction('update');

                }
                this.showModal = false;
            }

        },
        async getAllDevices() {
            var token_data = JSON.parse(localStorage.getItem('token_data'));

            this.isLoading = true;

            const inputs = {
                'user_token': localStorage.getItem('user_token'),
                'token_data': token_data,

            }
            try {
                let response = await api.request(process.env.VUE_APP_Get_all_device, inputs);

                this.isLoading = false;
                this.deviceData = response.data.result.data;
                this.filteredDeviceData = response.data.result.data;


            } catch (err) {
                console.log('Error in Partners:', err);
            }


        },

        async deviceAction(type) {
            var token_data = JSON.parse(localStorage.getItem('token_data'));

            this.isLoading = true;

            const inputs = {
                'category': this.form.category,
                'brand': this.form.brand,
                'device_name': this.form.name,
                'user_token': localStorage.getItem('user_token'),
                'token_data': token_data,
                'device_id': this.form?.id || ''

            }
            if (type === 'add') {
                try {
                    let response = await api.request(process.env.VUE_APP_Add_device, inputs);

                    this.isLoading = false;
                    this.deviceData = response.data.result.data;
                    this.$bvToast.toast('Device added succesfully', {
                        title: 'Success',
                        variant: 'success',
                        toaster: 'b-toaster-top-right'
                    })
                    this.getAllDevices();


                } catch (err) {
                    console.log('Error in Partners:', err);
                    this.$bvToast.toast('Error while Adding data.', {
                        title: 'Failed',
                        variant: 'danger',
                        toaster: 'b-toaster-top-right'
                    })

                    this.isLoading = fasle;
                }

            } else {
                try {
                    let response = await api.request(process.env.VUE_APP_Update_device, inputs);

                    this.isLoading = false;
                    this.deviceData = response.data.result.data;
                    this.$bvToast.toast('Device Updated succesfully', {
                        title: 'Success',
                        variant: 'success',
                        toaster: 'b-toaster-top-right'
                    })
                    this.getAllDevices();


                } catch (err) {
                    console.log('Error in Partners:', err);
                    this.$bvToast.toast('Error while updating data.', {
                        title: 'Failed',
                        variant: 'danger',
                        toaster: 'b-toaster-top-right'
                    });
                    this.isLoading = fasle;
                }

            }

        },
        searchData() {
            if (this.searchTerm.trim() === '') {
                this.filteredDeviceData = this.deviceData;
            } else {
                const lowerCaseSearchTerm = this.searchTerm.toLowerCase();
                this.filteredDeviceData = this.deviceData.filter(item => {

                    return item.name && item.name.toLowerCase().includes(lowerCaseSearchTerm);
                });
            }
        },

        closeModal() {
            this.showModal = false;
        },


        validateForm() {
            let isValid = true;



            // Start Date validation
            if (!this.form.brand) {
                this.errorMessages.brand = 'Brand is required.';
                isValid = false;
            } else {
                this.errorMessages.brand = '';
            }

            // End Date validation
            if (!this.form.name) {
                this.errorMessages.name = 'Device description is required.';
                isValid = false;
            } else {
                this.errorMessages.name = '';
            }

            // Partner Name validation
            if (!this.form.category) {
                this.errorMessages.category = 'Category is required.';
                isValid = false;
            } else {
                this.errorMessages.category = '';
            }





            return isValid;
        },

    },
    mounted() {
        this.getAllDevices();
    }
};
</script>

<style scoped>
.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: rgb(116, 239, 114);
    animation: spin 1s linear infinite;
}

.filer__box {
    gap: 1.5rem;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>
