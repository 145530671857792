<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
  <!-- <div>
    <download-excel  :data="data2" :fields="json_fields">
      <button type="button" class="btn btn-success">Download Excel</button>
    </download-excel>
  </div> -->
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            User List
          </h3>
        </div>

        <div class="p-2" >
          <b-form-select  :options="options" class="mt-4">
            <!-- This slot appears above the options from 'options' prop -->
            <template #first>
              <b-form-select-option :value="null" disabled>Country List</b-form-select-option>
            </template>

            <!-- These options will appear after the ones from 'options' prop -->
            <b-form-select-option value="UAE"  >
              UAE
            </b-form-select-option>
            <!-- <b-form-select-option value="D">Option D</b-form-select-option> -->
          </b-form-select>
          </div>
        <div>
          <b-modal
            id="modal-prevent-closing"
            ref="modal"
            scrollable
            title="Partner Form"
            @show="resetModal"
            @hidden="resetModal"
            hide-footer
            @ok="handleOk"
          >
          
            <div class="d-flex ">
            <div class="p-2 ">
              <label class="text-left"></label>
                <b-form-input
                  placeholder="Name"
                  :state="nameState"
                  v-model="userName"
                  class="inp"
                ></b-form-input>
            </div>
            <div class="p-2 ">
              <label class="text-left"></label>
                <b-form-input
                placeholder="Email"
                :state="emailState"
                v-model="userEmail"
                class="inp"
              ></b-form-input>
              </div>
            </div>
            <div class="d-flex">
          <div class="p-2 ">
          <label class="text-left"></label>
            
            <b-form-input
              type="text"
              onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
              max="999999999"
              placeholder="5XXXXXXXXXXX"
              :state="mobileState"
              v-model="userMobile"
              class="input-login inp"
              :formatter="mobileFormatter"
            ></b-form-input>
            </div>
            <div class="p-2" >
                  <label class="text-left"></label>
                  <b-form-input
                  placeholder="Company Name"
                  :state="addState"
                  v-model="useradd"
                  class=" inp"
                  ></b-form-input>
                </div>
              </div>
              <div class="text-center mt-3">
                <button type="button" class="btn btn-success" @click="signUp()">Confirm Partner</button>
              </div>
          </b-modal>
          </div>


          <!-- User Form  -->



        <div>
          <b-modal
            id="modal-user"
            ref="modal"
            scrollable
            title="User Form"
            @show="resetModal"
            @hidden="resetModal"
            hide-footer
            @ok="handleOk"
          >
          
            <div class="d-flex ">
            <div class="p-2 ">
              <label class="text-left"></label>
                <b-form-input
                  placeholder="Name"
                  :state="nameState4"
                  v-model="userName4"
                  class="inp"
                ></b-form-input>
            </div>
            <div class="p-2 ">
              <label class="text-left"></label>
                <b-form-input
                placeholder="Email"
                :state="emailState4"
                v-model="userEmail4"
                class="inp"
              ></b-form-input>
              </div>
            </div>
            <div class="d-flex">
          <div class="p-2 ">
          <label class="text-left"></label>
            
            <b-form-input
              type="text"
              onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
              max="999999999"
              placeholder="5XXXXXXXXXXX"
              :state="mobileState4"
              v-model="userMobile4"
              class="input-login inp"
              :formatter="mobileFormatter4"
            ></b-form-input>
            </div>
            <div class="p-2" >
                  <label class="text-left"></label>
                  <b-form-input
                  placeholder="Company Name"
                  :state="addState4"
                  v-model="useradd4"
                  class=" inp"
                  ></b-form-input>
                </div>

            
              </div>
              <div class="d-flex">
                <div class="p-2" >
                  <b-form-select v-model="partner" :options="options" class="mt-4">
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="null" disabled>Partner List</b-form-select-option>
                    </template>

                    <!-- These options will appear after the ones from 'options' prop -->
                    <b-form-select-option v-for="i in data" :key="i" :value="i.id" @change="getlocation(i.id)" >
                      {{ i.name }}
                    </b-form-select-option>
                    <!-- <b-form-select-option value="D">Option D</b-form-select-option> -->
                  </b-form-select>
                  </div>
                  <div class="p-2" >
                  <b-form-select v-model="location" :options="options" class="mt-4">
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="null" disabled>Location List</b-form-select-option>
                    </template>

                    <!-- These options will appear after the ones from 'options' prop -->
                    <b-form-select-option v-for="i in loc_data" :key="i" :value="i.id" >
                      {{ i.name }}
                    </b-form-select-option>
                    <!-- <b-form-select-option value="D">Option D</b-form-select-option> -->
                  </b-form-select>
                  </div>
              </div>
              <div class="d-flex">
                <div class="p-2" >
                  <b-form-select v-model="log_user" :options="options" class="mt-4">
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="null" disabled>Logistics List</b-form-select-option>
                    </template>

                    <!-- These options will appear after the ones from 'options' prop -->
                    <b-form-select-option v-for="i in data5" :key="i" :value="i.id" >
                      {{ i.name }}
                    </b-form-select-option>
                    <!-- <b-form-select-option value="D">Option D</b-form-select-option> -->
                  </b-form-select>
                  </div>
                  <div class="p-2" >
                  <b-form-select v-model="position_user" :options="options" class="mt-4">
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="null" disabled>Position List</b-form-select-option>
                    </template>

                    <!-- These options will appear after the ones from 'options' prop -->
                    <b-form-select-option value="Manager"  >
                      Manager
                    </b-form-select-option>
                    <b-form-select-option  value="User" >
                      User
                    </b-form-select-option>
                    <!-- <b-form-select-option value="D">Option D</b-form-select-option> -->
                  </b-form-select>
                  </div>
              </div>
              <div class="text-center mt-3">
                <button type="button" class="btn btn-success" @click="User_Cre()">Confirm User</button>
              </div>
          </b-modal>
          </div>



          <!-- Location Form  -->




          <div>
          <b-modal
            id="modal-location"
            ref="modal"
            scrollable
            title="Location Form"
            @show="resetModal"
            @hidden="resetModal"
            hide-footer
            @ok="handleOk"
          >
          
            <div class="p-2 ">
              <label class="text-left"></label>
                <b-form-input
                  placeholder="Address"
                  :state="nameState2"
                  v-model="userName2"
                  class="inp"
                ></b-form-input>
            </div>
            <div class="d-flex">
          <div class="p-2 ">
          <label class="text-left"></label>
            
            <b-form-input
              type="text"
              onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
              max="999999999"
              placeholder="Percentage %"
              :state="mobileState2"
              v-model="userMobile2"
              class="input-login inp"
              :formatter="mobileFormatter2"
            ></b-form-input>
            </div>
            <div class="p-2" >
              <b-form-select v-model="location" :options="options" class="mt-4">
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                  <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                </template>

                <!-- These options will appear after the ones from 'options' prop -->
                <b-form-select-option v-for="i in data" :key="i" :value="i.id" >
                  {{ i.name }}
                </b-form-select-option>
                <!-- <b-form-select-option value="D">Option D</b-form-select-option> -->
              </b-form-select>
              </div>
              </div>
              <div class="text-center mt-3">
                <button type="button" class="btn btn-success" @click="setlocation()">Confirm Location</button>
              </div>
          </b-modal>
          </div>


          <!-- Logistics Form  -->



        <div>
          <b-modal
            id="modal-Logistics"
            ref="modal"
            scrollable
            title="Logistics Form"
            @show="resetModal"
            @hidden="resetModal"
            hide-footer
            @ok="handleOk"
          >
          
            <div class="d-flex ">
            <div class="p-2 ">
              <label class="text-left"></label>
                <b-form-input
                  placeholder="Name"
                  :state="nameState3"
                  v-model="userName3"
                  class="inp"
                ></b-form-input>
            </div>
            <div class="p-2 ">
              <label class="text-left"></label>
                <b-form-input
                placeholder="Email"
                :state="emailState3"
                v-model="userEmail3"
                class="inp"
              ></b-form-input>
              </div>
            </div>
            <div class="d-flex">
          <div class="p-2 ">
          <label class="text-left"></label>
            
            <b-form-input
              type="text"
              onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
              max="999999999"
              placeholder="5XXXXXXXXXXX"
              :state="mobileState3"
              v-model="userMobile3"
              class="input-login inp"
              :formatter="mobileFormatter3"
            ></b-form-input>
            </div>
            <div class="p-2" >
                  <label class="text-left"></label>
                  <b-form-input
                  placeholder="Company Name"
                  :state="addState3"
                  v-model="useradd3"
                  class=" inp"
                  ></b-form-input>
                </div>
              </div>
              <div class="text-center mt-3">
                <button type="button" class="btn btn-success" @click="Logistics()">Confirm Logistics</button>
              </div>
          </b-modal>
          </div>













      </div>
    </div>
    <div>
      <b-card no-body>
        <b-tabs  card vertical>
          <b-tab title="Partner" @click="getDetails2('Partner','Partner')"  active style="padding: 0px !important;height: 10px !important;" ><b-card-text>
            <b-card no-body   style="padding: 0px !important; border: none; ">
              <!-- <b-tabs align="center" style="padding: 0px !important; border: none !important;; ">
                <b-tab v-for="i in data" :key="i" :title="i.name" @click="getDetails2('Partner',i.id)"><b-card-text> -->
                  <button type="button" class="btn btn-success" v-b-modal.modal-prevent-closing style="width: fit-content;">Add Partner</button>

                  <div class="block w-full overflow-x-auto " style="height: 400px;">
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          ID
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Name
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Company
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Configure
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in data2" :key="i" @click="getBuyback(i.id)">
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.id }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.name }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.com }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              <a href="#">
                                <img src="../../assets/img/square-sliders-vertical-icon-2048x2048-plq7h2da.png" width="20px"/>
                              </a>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                <!-- </b-card-text></b-tab> -->
              <!-- </b-tabs> -->
              
            </b-card>
          </b-card-text></b-tab>
          <b-tab title="User" @click="getDetails2('User',1)"  style="padding: 0px !important;height: 10px !important;" ><b-card-text>
            <b-card no-body   style="padding: 0px !important; border: none; ">
              <b-tabs align="center" style="padding: 0px !important; border: none !important;; ">

                <b-tab v-for="i in data" :key="i" :title="i.name" @click="getDetails2('User',i.id)"><b-card-text>
                  <button type="button" class="btn btn-success" v-b-modal.modal-user> Add User</button>

                  <div class="block w-full overflow-x-auto " style="height: 400px;">
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          ID
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          User Name
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Location
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Position
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Configure
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in data2" :key="i" @click="getBuyback(i.id)">
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.id }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.name }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.position }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.location }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              <a href="#">
                                <img src="../../assets/img/square-sliders-vertical-icon-2048x2048-plq7h2da.png" width="20px"/>
                              </a>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-text></b-tab>
              </b-tabs>
              
            </b-card>
          </b-card-text></b-tab>
          <b-tab title="Location" @click="getDetails2('Location',1)"  style="padding: 0px !important;height: 10px !important;" ><b-card-text>
            <b-card no-body   style="padding: 0px !important; border: none; ">
              <b-tabs  align="center" style="padding: 0px !important; border: none !important;; ">
                <b-tab v-for="i in data" :key="i" :title="i.name" @click="getDetails2('Location',i.id)"><b-card-text>
                  <button type="button" class="btn btn-success" v-b-modal.modal-location> Add Location</button>
                  <div class="block w-full overflow-x-auto" style="height: 400px;">
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Location ID
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Location
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Rate
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in data2" :key="i" @click="getBuyback(i.id)">
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.id }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.name }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.rate }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              <a href="#">
                                <img src="../../assets/img/square-sliders-vertical-icon-2048x2048-plq7h2da.png" width="20px"/>
                              </a>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-text></b-tab>
              </b-tabs>
              
            </b-card>
          </b-card-text></b-tab>
          <b-tab title="Logistics" @click="getDetails2('Logistics','Logistics')"  style="padding: 0px !important;height: 10px !important;" ><b-card-text>
            <b-card no-body   style="padding: 0px !important; border: none; ">
              <!-- <b-tabs  align="center" style="padding: 0px !important; border: none !important;; ">
                <b-tab v-for="i in data" :key="i" :title="i.name" @click="getDetails2('Tablets',i.name)"><b-card-text> -->
                  <button type="button" class="btn btn-success" v-b-modal.modal-Logistics style="width: fit-content;">Add Logistics</button>

                  <div class="block w-full overflow-x-auto" style="height: 400px;">
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          ID
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Name
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          User
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Email
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Mobile
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Location
                          </th>
                          <th
                            class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                            ]"
                          >
                          Configure
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in data2" :key="i" @click="getBuyback(i.id)">
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.id }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.name }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.user }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.email }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.mob }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {{ i.location }}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              <a href="#">
                                <img src="../../assets/img/square-sliders-vertical-icon-2048x2048-plq7h2da.png" width="20px"/>
                              </a>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                <!-- </b-card-text></b-tab>
              </b-tabs> -->
              
            </b-card>
          </b-card-text></b-tab>
        </b-tabs>
      </b-card>
    </div>
    <div>
    <b-modal ref="my-modal" hide-footer no-close-on-backdrop no-close-on-esc title="Price List">
      <div class="d-block text-center">
        <h3>Uploaded Successfully</h3>
      </div>
      <b-button class="mt-3" variant="outline-danger" block @click="toggleModal()">Done</b-button>
    </b-modal>
  </div>
  </div>
</template>
<script>
import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";

import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import api from '../../api'

export default {
  data() {
    return {
      userName: this.$store.state.signUpForm ? this.$store.state.signUpForm.name : '',
      userName2: this.$store.state.signUpForm ? this.$store.state.signUpForm.name2: '',
      useradd: this.$store.state.signUpForm ? this.$store.state.signUpForm.useradd : '',
      userEmail: this.$store.state.signUpForm ? this.$store.state.signUpForm.email : '',
      userMobile: this.$store.state.signUpForm ? this.$store.state.signUpForm.mobile : '',
      userMobile2: this.$store.state.signUpForm ? this.$store.state.signUpForm.mobile2 : '',
      userName3: this.$store.state.signUpForm ? this.$store.state.signUpForm.name3 : '',
      useradd3: this.$store.state.signUpForm ? this.$store.state.signUpForm.useradd3 : '',
      userEmail3: this.$store.state.signUpForm ? this.$store.state.signUpForm.email3 : '',
      userMobile3: this.$store.state.signUpForm ? this.$store.state.signUpForm.mobile3 : '',
      userName4: this.$store.state.signUpForm ? this.$store.state.signUpForm.name4 : '',
      useradd4: this.$store.state.signUpForm ? this.$store.state.signUpForm.useradd4 : '',
      userEmail4: this.$store.state.signUpForm ? this.$store.state.signUpForm.email4 : '',
      userMobile4: this.$store.state.signUpForm ? this.$store.state.signUpForm.mobile4 : '',
      file5: null,
      id: this.$route.params.id,
      bootstrap,
      log_user:null,
      angular,
      sketch,
      react,
      data5:null,
      loc_data:null,
      vue,
      location:null,
      partner:null,
      position_user:null,
      team1,
      team2,
      team3,
      team4,
      data:null,
      data2:null,
      json_fields:{
        "Device":"name",
        "Grade A":"a",
        "Grade B":"b",
        "Grade C":"c"
        },
        json_data: []
    };
  },
  components: {
    TableDropdown,
  },
  computed: {
    nameState() {
      return this.userName.length > 3 ? true : false
    },
    nameState2() {
      return this.userName2.length > 3 ? true : false
    },
    addState() {
      return this.useradd.length > 6 ? true : false
    },
    emailState() {
      if (this.userEmail.length < 5 || this.userEmail.length) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(this.userEmail).toLowerCase())
    } else {
      return null
    }
    },
    
    mobileState: function () {
    if (this.userMobile.length < 9 || this.userMobile.length) {
      return this.userMobile.length === 9
    } else {
      return null
    }
  },

  nameState3() {
      return this.userName3.length > 3 ? true : false
    },
    addState3() {
      return this.useradd3.length > 6 ? true : false
    },
    emailState3() {
      if (this.userEmail3.length < 5 || this.userEmail3.length) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(this.userEmail3).toLowerCase())
    } else {
      return null
    }
    },
    
    mobileState3: function () {
    if (this.userMobile3.length < 9 || this.userMobile3.length) {
      return this.userMobile3.length === 9
    } else {
      return null
    }
  },
  nameState4() {
      return this.userName4.length > 3 ? true : false
    },
    addState4() {
      return this.useradd4.length > 6 ? true : false
    },
    emailState4() {
      if (this.userEmail4.length < 5 || this.userEmail4.length) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(this.userEmail4).toLowerCase())
    } else {
      return null
    }
    },
    
    mobileState4: function () {
    if (this.userMobile4.length < 9 || this.userMobile4.length) {
      return this.userMobile4.length === 9
    } else {
      return null
    }
  },
  // mobileState2: function () {
  //   if (this.userMobile2.length < 2 || this.userMobile2.length) {
  //     return this.userMobile2.length === 2
  //   } else {
  //     return null
  //   }
  // }
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    mobileFormatter: function (e) {
        return String(e).substring(0, 9)
        },
    mobileFormatter3: function (e) {
        return String(e).substring(0, 9)
        },
    mobileFormatter2: function (e) {
        return String(e).substring(0, 3)
        },
    mobileFormatter4: function (e) {
        return String(e).substring(0, 9)
        },
    // getBuyback: function (id) {
    //   this.$router.push({ path: '/admin/batch/'+ id, params: { 'id': id } })
    // },
    showimg: function (id) {
      this.$bvModal.show(id)
    },
    onChange5(e) {
        if (! e.target.files.length) return;
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
            let src = e.target.result;
            this.file5 = e.target.result;
  
        };
      },
      upload_file: function () {
        var token_data = JSON.parse(localStorage.getItem('token_data'));
        const inputs = {
         
              'attachment': this.file5.replace('data:', '').replace(/^.+,/, ''),
              'file_type5':'data',
              'type':'xlsx',
              'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
         
        }
        api.request(process.env.VUE_APP_Upload_Pricing, inputs).then((response) => {
          const data = response.data
          if (data.errorcode) {
            this.errormessage = data.errormessage
          } else if (data.result && typeof data.result === 'object') {
            this.data = data.result
            this.$refs['modal-12'].show()
          } else if (data.result && typeof data.result === 'string') {
            this.errormessage = data.result
          }
        }).catch((error) => {
          this.errormessage = error
        })
      },
    getDetails: function (id) {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
        
            'cate': id,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
          
      }
      api.request(process.env.VUE_APP_Get_Pricing_Data3, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result && typeof data.result === 'object') {
          this.data = data.result
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    toggleModal() {
      this.$router.go()
    },
    getDetails2: function (cate,brand) {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
        
            'id': brand,
            'select': cate,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
          
      }
      api.request(process.env.VUE_APP_Get_Pricing_Data4, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result && typeof data.result === 'object') {
          this.data2 = data.result
          for (let i = 0; i<= this.data2.length; i++) {
            console.log(i)
            this.json_data[i].append({
              'name': this.data2[i].name
            })
            console.log(this.json_data)
          }
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    getDetails3: function (cate,brand) {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
        
            'id': brand,
            'select': cate,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
          
      }
      api.request(process.env.VUE_APP_Get_Pricing_Data4, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result && typeof data.result === 'object') {
          this.data5 = data.result
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    setlocation: function () {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'r.location',
          'method': 'SetLocation',
          'args': {
            'id': this.location,
            'ratio': this.userMobile2,
            'location': this.userName2
          }
        },
        'modecode': 100
      }
      api.request('post', 'update', inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result ) {
          window.location.reload()

        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    getlocation: function (ids) {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
        
            'id': ids,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
          
      }
      api.request(process.env.VUE_APP_Get_Parnter_Data2, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result ) {
          this.loc_data = data.result

        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    signUp: function () {
      this.signUpMessage = ''
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'r.partner',
          'method': 'data_user_creation',
          'args': { 'name': this.userName,
          'login': this.userEmail,
          'password': 'ABCddd',
          'mobile': '971' + this.userMobile,
          'address': this.useradd,
          'partner':this.partner,
          'loc':this.location
         }
        },
        'modecode': 100
      }
      console.log('User Creation input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('user creation restposnse:', data.result)
            // this.userPassword = 'password'
            this.loginName = this.userEmail
            if (data.result[0].message === 'User Already Exist') {
              this.signUpMessage = 'Email or Phone Number Already Exists!'
            } else {
              window.location.reload()
            }
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    User_Cre: function () {
      this.signUpMessage = ''
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'r.users',
          'method': 'data_user_creation',
          'args': { 'name': this.userName4,
          'login': this.userEmail4,
          'password': 'ABCddd',
          'mobile': '971' + this.userMobile4,
          'address': this.useradd4,
          'position':this.position_user,
          'partner':this.partner,
          'loc':this.location,
          'log':this.log_user
         }
        },
        'modecode': 100
      }
      console.log('User Creation input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('user creation restposnse:', data.result)
            // this.userPassword = 'password'
            this.loginName = this.userEmail
            if (data.result[0].message === 'User Already Exist') {
              this.signUpMessage = 'Email or Phone Number Already Exists!'
            } else {
              window.location.reload()
            }
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    Logistics: function () {
      this.signUpMessage = ''
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'r.logistics',
          'method': 'data_user_creation',
          'args': { 'name': this.userName3,
          'login': this.userEmail3,
          'password': 'ABCddd',
          'mobile': '971' + this.userMobile3,
          'address': this.useradd3,
         }
        },
        'modecode': 100
      }
      console.log('User Creation input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('user creation restposnse:', data.result)
            // this.userPassword = 'password'
            this.loginName = this.userEmail
            if (data.result[0].message === 'User Already Exist') {
              this.signUpMessage = 'Email or Phone Number Already Exists!'
            } else {
              window.location.reload()
            }
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
  },
  mounted () {
      this.getDetails()
      this.getDetails2('Partner','Partner')

      this.getDetails3('Logistics','Logistics')
  }
};
</script>

<style scoped>
ul.a {
  list-style-type: circle;
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  text-align:justify;
}
</style>