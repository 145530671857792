<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            Location List
          </h3>
        </div>
        <b-button @click="showModal2()">All Prices</b-button>

      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Location ID
            </th>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Address
            </th>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Company Rates
            </th>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Redington Rates
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in data" :key="i" @click="showModal(i.id)">
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                LR00{{ i.id }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ i.name }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ i.com_name }}%
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ i.red_name }}%
              </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
    <b-modal ref="my-modal" hide-footer title="Change The Redington Rate">
      <div class="d-block text-left">
        <h5 style="font-weight: 600;">Partner Percentage</h5>
        <b-form-input v-model="text" placeholder="Enter Partner Percentage" class="mt-2"></b-form-input>
      </div>
      <div class="d-block text-left mt-3">
        <h5 style="font-weight: 600;">Redington Percentage</h5>
        <b-form-input v-model="text2" placeholder="Enter Redington Percentage" class="mt-2"></b-form-input>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="SetDetails()">Confirm</b-button>
      <!-- <b-button class="mt-2" variant="outline-warning" block @click="toggleModal">Toggle Me</b-button> -->
    </b-modal>
  </div>
  <div>
    <b-modal ref="my-modal2" hide-footer title="Change The Rates for all the Locations">
      <div class="d-block text-left">
        <h5 style="font-weight: 600;">Partner Percentage</h5>
        <b-form-input v-model="text" placeholder="Enter Partner Percentage" class="mt-2"></b-form-input>
      </div>
      <div class="d-block text-left mt-3">
        <h5 style="font-weight: 600;">Redington Percentage</h5>
        <b-form-input v-model="text2" placeholder="Enter Redington Percentage" class="mt-2"></b-form-input>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="SetDetails2()">Confirm</b-button>
      <!-- <b-button class="mt-2" variant="outline-warning" block @click="toggleModal">Toggle Me</b-button> -->
    </b-modal>
  </div>
  </div>
</template>
<script>
import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";

import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import api from '../../api'

export default {
  data() {
    return {
      text: '',
      text2: '',
      id: this.$route.params.id,
      bootstrap,
      angular,
      sketch,
      react,
      vue,
      team1,
      team2,
      team3,
      team4,
      data:null,
      name:null
    };
  },
  components: {
    TableDropdown,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    showModal(i) {
      this.name = i
      this.$refs['my-modal'].show()
    },
    showModal2() {
      this.$refs['my-modal2'].show()
    },
    getDetails: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
      
            'id': this.id,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
         
      }
      api.request(process.env.VUE_APP_Get_Parnter_Data2, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result && typeof data.result === 'object') {
          this.data = data.result
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    SetDetails: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
       
            'id': this.name,
            'ratio': this.text,
            'ratio2':this.text2,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
          
      }
      api.request(process.env.VUE_APP_Set_Parnter_Data, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result) {
          this.data = data.result
          this.$router.go()
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    SetDetails2: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
       
            'id': this.id,
            'ratio': this.text,
            'ratio2':this.text2,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
      
      }
      api.request(process.env.VUE_APP_Set_Parnter_Data2, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result) {
          this.data = data.result
          this.$router.go()
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    }
  },
  mounted () {
    if (!this.$store.state.user) {
      this.errormessage = 'To view orders, please log in'
      this.$bvModal.show('modalSignIn')
    } else {
      this.getDetails()
    }
  }
};
</script>
