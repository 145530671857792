<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            OverView
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Location ID
            </th>
            <th v-if="!user.partner"
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Vendor
            </th>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Location
            </th>
            
            
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Quantity
            </th>
            <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Price
            </th>
            <!-- <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Status
            </th> -->
            <!-- <th
              class="px-6 align-middle  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Date
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in data" :key="i" >
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                RB00{{ i.id }}
              </td>
              <td v-if="!user.partner" class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ i.name }}
              </td>
              <td  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ i.name }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                {{ i.qty }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                {{ i.price }}
              </td>
              <!-- <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                <i v-if="i.status === 'Complete'" class="fas fa-circle text-emerald-500 mr-2"></i>
                <i v-else class="fas fa-circle text-orange-500 mr-2"></i>{{ i.status }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                {{ i.date }}
              </td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";

import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import api from '../../api'

export default {
  data() {
    return {
      bootstrap,
      angular,
      sketch,
      react,
      vue,
      team1,
      team2,
      team3,
      team4,
      data:null,
      user: this.$store.state.user,

    };
  },
  components: {
    TableDropdown,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    getBuyback: function (id) {
      this.$router.push({ path: '/admin/batch/'+ id, params: { 'id': id } })
    },
    getOrders: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      
      const inputs = {
        
            'user_id': this.$store.state.user.uid,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
            'country':this.$store.state.partner
         
      }
      api.request(process.env.VUE_APP_Get_Data_list2, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result && typeof data.result === 'object') {
          this.data = data.result
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    getOrders2: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
       
            'user_id': this.$store.state.user.uid,
            'user_token':localStorage.getItem('user_token'),
            'token_data':token_data,
            
        
      }
      api.request(process.env.VUE_APP_Datalist_Partner2, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result && typeof data.result === 'object') {
          this.data = data.result
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    }
  },
  mounted () {
    if (!this.$store.state.user) {
      this.errormessage = 'To view orders, please log in'
      this.$bvModal.show('modalSignIn')
    } else {
        if (this.user.partner === true) {
          this.getOrders2()
        } else {
          this.getOrders()
        }
    }
  }
};
</script>
