<template>
    <div class="flex flex-wrap mt-4">
        <div class="w-full mb-12 px-4">
            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded" :class="['bg-white']">
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="d-flex justify-content-between relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-lg" :class="['text-blueGray-700']">
                                Top ups
                            </h3>
                            <div class="d-flex filer__box">
                                <div class="input-group">
                                    <input type="text" class="form-control rounded" @input="searchData"
                                        placeholder="Search topup by name...." aria-label="Username"
                                        aria-describedby="basic-addon1" v-model="searchTerm">
                                </div>
                                <b-button @click="openModal('add')">Add</b-button>
                                <b-button v-if="!this.dateFilter" @click="openModal('filter')"><i
                                        class="fas fa-filter"></i></b-button>
                                <b-button v-if="this.dateFilter" @click="removeDateFilter">Clear</b-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block w-full overflow-x-auto">
                    <!-- Spinner -->
                    <div v-if="isLoading" class="flex justify-center items-center py-8">
                        <div class="loader"></div>
                    </div>

                    <!-- Table -->
                    <table v-else class="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th
                                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Sr no.
                                </th>
                                <th
                                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Partner
                                </th>
                                <th
                                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Promo Name
                                </th>
                                <th
                                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Price
                                </th>
                                <th
                                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Start Date
                                </th>
                                <th
                                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    End Date
                                </th>
                                <th
                                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Edit
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in paginatedData" :key="index">
                                <td
                                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {{ index + 1 }}
                                </td>
                                <td
                                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {{ item.partner_name.name }}
                                </td>
                                <td
                                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {{ item.promo }}
                                </td>
                                <td
                                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {{ item.price }}
                                </td>
                                <td
                                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {{ item.start_date }}
                                </td>
                                <td
                                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {{ item.end_date }}
                                </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 cursor-pointer"
                                    @click="openModal('edit', item)">
                                    <i class="fas fa-pencil"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="flex justify-end m-4">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="my-table"></b-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <b-modal v-model="showModal" :title="modalTitle" hide-footer>
            <div v-if="this.modalTitle === 'Add Promotion' || this.modalTitle === 'Edit Promotion'">
                <form>
                    <!-- 1. Partner Select input (Already there) -->
                    <div class="mb-3">
                        <label for="partnerName" class="form-label">Partner Name</label>
                        <v-select :options="partnerData" label="name" v-model="form.partner_name"
                            placeholder="Search for a partner..." required>
                        </v-select>
                        <span class="text-danger">{{ errorMessages.partner_name }}</span>
                    </div>

                    <!-- 2. Two adjacent date input (start_date and end_date) -->
                    <div class="mb-3 d-flex justify-content-between">
                        <div class="me-2">
                            <label for="startDate" class="form-label">Start Date</label>
                            <input type="date" class="form-control" id="startDate" v-model="form.start_date" required>
                            <span class="text-danger">{{ errorMessages.start_date }}</span>
                        </div>
                        <div>
                            <label for="endDate" class="form-label">End Date</label>
                            <input type="date" class="form-control" id="endDate" v-model="form.end_date" required>
                            <span class="text-danger">{{ errorMessages.end_date }}</span>
                        </div>
                    </div>

                    <!-- 3. Searchable Select input for Location -->

                    <div class="mb-3">
                        <label for="location" class="form-label">Location</label>
                        <v-select :options="locationData" label="name" v-model="form.location"
                            placeholder="Search for a location..." @search="getAllLocation" :multiple="true" required>
                        </v-select>
                    </div>

                    <!--  Trade in device field as select input -->
                    <div class="mb-3">
                        <label for="TradeDevice" class="form-label">New Device</label>
                        <v-select :options="tradeInDeviceData" label="name" v-model="form.trade_device"
                            placeholder="Search for a device model..." :multiple="true"
                            @search="(searchTerm) => getAllDevices(searchTerm, 'tradeIn')" required>
                        </v-select>
                        <span class="text-danger">{{ errorMessages.trade_device }}</span>
                    </div>

                    <!-- 4. Min fields for char -->
                    <div class="mb-3 d-flex justify-content-between">
                        <div class="mb-3 mr-2">
                            <label for="minChar" class="form-label">Min </label>
                            <input type="number" class="form-control" id="minChar" v-model="form.min_price" required>
                            <span class="text-danger">{{ errorMessages.min_price }}</span>
                        </div>

                        <!-- 5. Max fields for char -->
                        <div class="mb-3">
                            <label for="maxChar" class="form-label">Max </label>
                            <input type="number" class="form-control" id="maxChar" v-model="form.max_price" required>
                            <span class="text-danger">{{ errorMessages.max_price }}</span>
                        </div>
                    </div>

                    <!-- 6. Price fields for char -->
                    <div class="mb-3">
                        <label for="charPrice" class="form-label">Price</label>
                        <input type="number" class="form-control" id="charPrice" v-model="form.price" required>
                        <span class="text-danger">{{ errorMessages.price }}</span>
                    </div>

                    <!-- 7. Promotion Name field for char -->
                    <div class="mb-3">
                        <label for="charPromoName" class="form-label">Promotion Name</label>
                        <input type="text" class="form-control" id="charPromoName" v-model="form.promo" required>
                        <span class="text-danger">{{ errorMessages.promo }}</span>
                    </div>

                    <!-- 8. Category field as select input -->
                    <div class="mb-3">
                        <label for="category" class="form-label">Category</label>
                        <v-select :options="categoryData" label="name" v-model="form.category"
                            placeholder="Search for a category..." :multiple="true" required>
                        </v-select>
                        <span class="text-danger">{{ errorMessages.category }}</span>
                    </div>
                    <!-- 9. Applied on Devices field as select input -->
                    <div class="mb-3">
                        <label for="appliedDevice" class="form-label">Applied on devices</label>
                        <v-select :options="deviceData" label="name" v-model="form.applied_device"
                            placeholder="Search for a device model..." :multiple="true"
                            @search="(searchTerm) => getAllDevices(searchTerm, 'applied')" required>
                        </v-select>
                    </div>
                    <!-- 10. Checkboxes for conditions -->
                    <div class="mb-3">
                        <label class="form-label">Conditions</label>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="condition1" v-model="form.condition_1">
                            <label class="form-check-label" for="condition1">Condition 1</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="condition2" v-model="form.condition_2">
                            <label class="form-check-label" for="condition2">Condition 2</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="condition3" v-model="form.condition_3">
                            <label class="form-check-label" for="condition3">Condition 3</label>
                        </div>
                        <span class="text-danger">{{ errorMessages.conditions }}</span>

                    </div>
                </form>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
                    <button type="submit" class="btn btn-primary" @click="handleSubmit">Save</button>
                </div>
            </div>
            <div v-else>
                <div>
                    <b-form-group label="Start Date" label-for="start-date">
                        <b-form-datepicker id="start-date" v-model="startDate" required
                            placeholder="Select start date"></b-form-datepicker>
                    </b-form-group>
                    <b-form-group label="End Date" label-for="end-date">
                        <b-form-datepicker id="end-date" v-model="endDate" required
                            placeholder="Select end date"></b-form-datepicker>
                    </b-form-group>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
                        <button type="submit" class="btn btn-primary" @click="applyDateFilter">Apply</button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from '../../api';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            rows: 0,
            promoData: [],
            partnerData: [],
            locationData: [],
            categoryData: [],
            tradeInDeviceData: [],
            deviceData: [],
            dateFilter: false,
            searchTerm: '',
            startDate: null,
            endDate: null,
            filteredPromoData: [],
            isLoading: true,
            showModal: false,
            modalTitle: '',
            form: {
                partner_name: null,
                start_date: null,
                end_date: null,
                location: [],
                trade_device: null,
                min_price: null,
                max_price: null,
                price: null,
                promo: null,
                category: null,
                applied_device: [],
                condition_1: false,
                condition_2: false,
                condition_3: false,

            },
            errorMessages: {
                partner_name: '',
                start_date: '',
                end_date: '',
                trade_device: '',
                min_price: '',
                max_price: '',
                price: '',
                promo: '',
                category: '',
                conditions: ''
            }

        };
    },
    components: {
        vSelect,
    },
    computed: {
        paginatedData() {

            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;

            this.rows = this.filteredPromoData.length;
            return this.filteredPromoData.slice(start, end);

        }
    },
    methods: {
        async getAllPromotions() {
            this.isLoading = true;
            var token_data = JSON.parse(localStorage.getItem('token_data'));
            const inputs = {
                'user_token': localStorage.getItem('user_token'),
                'token_data': token_data,
            };
            let response = await api.request(process.env.VUE_APP_Get_All_Promos, inputs);

            this.promoData = response.data.result.data;
            this.filteredPromoData = response.data.result.data;

            this.isLoading = false;
        },
        openModal(type, item = null) {
            this.showModal = true;
            if (type === 'add') {
                this.modalTitle = 'Add Promotion';

                this.form = { partner_name: '', promo: '', price: null };
            } else if (type === 'edit') {
                this.modalTitle = 'Edit Promotion';
                this.form = { ...item };
                this.form = {
                    ...item,
                    promo_id: item.id,
                };
            } else {
                this.modalTitle = type;
            }
        },
        handleSubmit() {
            if (this.validateForm()) {
                if (this.modalTitle === 'Add Promotion') {
                    this.promoAction('add');

                    this.form = {
                        partner_name: '',
                        promo: '',
                        price: '',
                        location: '',
                        category: '',
                        device: '',
                        min_price: '',
                        max_price: '',
                        condition_1: '',
                        condition_2: '',
                        condition_3: '',
                        start_date: '',
                        end_date: '',
                        tradeInDevice: ''
                    }

                } else if (this.modalTitle === 'Edit Promotion') {
                    this.promoAction('update');


                }
                this.showModal = false;
            }

        },
        async getAllPartner() {
            var token_data = JSON.parse(localStorage.getItem('token_data'));

            const inputs = {

                'user_id': this.$store.state.user.uid,
                'country': this.$store.state.partner,
                'user_token': localStorage.getItem('user_token'),
                'token_data': token_data,

            }
            try {
                let response = await api.request(process.env.VUE_APP_Get_Partner_Data, inputs);
                this.partnerData = response.data.result;
                console.log('D:', response)

            } catch (err) {
                console.log('Error in Partners:', err);
            }


        },
        async getAllLocation(searchTerm) {
            var token_data = JSON.parse(localStorage.getItem('token_data'));

            const inputs = {

                'user_id': this.$store.state.user.uid,
                'country': this.$store.state.partner,
                'user_token': localStorage.getItem('user_token'),
                'token_data': token_data,
                'search_term': searchTerm

            }
            try {
                let response = await api.request(process.env.VUE_APP_Get_All_Location, inputs);
                this.locationData = response.data.result.data;
                console.log('D:', response)

            } catch (err) {
                console.log('Error in Partners:', err);
            }


        },
        async getAllCategory() {
            var token_data = JSON.parse(localStorage.getItem('token_data'));

            const inputs = {
                'user_token': localStorage.getItem('user_token'),
                'token_data': token_data,

            }
            try {
                let response = await api.request(process.env.VUE_APP_Get_All_Categories, inputs);
                this.categoryData = response.data.result[0];


            } catch (err) {
                console.log('Error in Partners:', err);
            }


        },
        async getAllDevices(searchTerm, type) {
            var token_data = JSON.parse(localStorage.getItem('token_data'));

            if (searchTerm.length < 3) return;

            const inputs = {
                'search_term': searchTerm,
                'user_token': localStorage.getItem('user_token'),
                'token_data': token_data,

            }
            try {
                let response = await api.request(process.env.VUE_APP_Search_consumer_device, inputs);

                if (type === 'applied') {
                    this.deviceData = response.data.result.data;
                } else {
                    this.tradeInDeviceData = response.data.result.data;
                }

            } catch (err) {
                console.log('Error in Partners:', err);
            }


        },
        async promoAction(type) {
            var token_data = JSON.parse(localStorage.getItem('token_data'));
            let inputs = {
                'start_date': this.form.start_date,
                'end_date': this.form.end_date,
                'partner_id': this.form.partner_name.id,
                'old_device': this.form.trade_device?.map(trad => trad.id),
                'category': this.form.category?.map(cate => cate.id),
                'applied_device': this.form.applied_device?.map(apply => apply.id),
                'location': this.form.location?.map(loc => loc.id),
                'condition1': this.form.condition_1,
                'condition2': this.form.condition_2,
                'condition3': this.form.condition_3,
                'price': this.form.price,
                'min_price': this.form.min_price,
                'max_price': this.form.max_price,
                'promo_name': this.form.promo,
                'user_token': localStorage.getItem('user_token'),
                'token_data': token_data,
                'promo_id': this.form.promo_id
            }
            if (type === 'add') {
                try {
                    let response = await api.request(process.env.VUE_APP_Create_promo, inputs);
                    console.log('response', response);
                    this.$bvToast.toast('Topup added succesfully', {
                        title: 'Success',
                        variant: 'success',
                        toaster: 'b-toaster-top-right'
                    });
                    this.getAllPromotions();

                } catch (err) {
                    console.log('Error:', err);
                    this.$bvToast.toast('Failed to add topup!', {
                        title: 'Error',
                        variant: 'danger',
                        toaster: 'b-toaster-top-right'
                    });
                }
            } else {

                try {
                    let response = await api.request(process.env.VUE_APP_Update_promo, inputs);
                    console.log('response', response);
                    this.$bvToast.toast('Topup Updated succesfully', {
                        title: 'Success',
                        variant: 'success',
                        toaster: 'b-toaster-top-right'
                    })
                    this.getAllPromotions();

                } catch (err) {
                    this.$bvToast.toast('Error Updating Topup', {
                        title: 'Error',
                        variant: 'danger',
                        toaster: 'b-toaster-top-right'
                    })
                    console.log("error", err)
                }
            }

        },
        closeModal() {
            this.showModal = false;
        },
        validateForm() {
            let isValid = true;

            // Partner Name validation
            if (!this.form.partner_name) {
                this.errorMessages.partner_name = 'Partner name is required.';
                isValid = false;
            } else {
                this.errorMessages.partner_name = '';
            }

            // Start Date validation
            if (!this.form.start_date) {
                this.errorMessages.start_date = 'Start date is required.';
                isValid = false;
            } else {
                this.errorMessages.start_date = '';
            }

            // End Date validation
            if (!this.form.end_date) {
                this.errorMessages.end_date = 'End date is required.';
                isValid = false;
            } else {
                this.errorMessages.end_date = '';
            }

            // Trade-in Device validation
            if (this.form.trade_device === undefined) {
                console.log("jbfjkd")
                this.errorMessages.trade_device = 'At least one trade-in device is required.';
                isValid = false;
            } else {
                this.errorMessages.trade_device = '';
            }

            // Min Price validation
            if (!this.form.min_price) {
                this.errorMessages.min_price = 'Minimum price is required.';
                isValid = false;
            } else {
                this.errorMessages.min_price = '';
            }

            // Max Price validation
            if (!this.form.max_price) {
                this.errorMessages.max_price = 'Maximum price is required.';
                isValid = false;
            } else {
                this.errorMessages.max_price = '';
            }

            // Price validation
            if (!this.form.price) {
                this.errorMessages.price = 'Price is required.';
                isValid = false;
            } else {
                this.errorMessages.price = '';
            }

            // Promotion Name validation
            if (!this.form.promo) {
                this.errorMessages.promo = 'Promotion name is required.';
                isValid = false;
            } else {
                this.errorMessages.promo = '';
            }

            // Category validation
            if (this.form?.category === undefined) {
                this.errorMessages.category = 'At least one category is required.';
                isValid = false;
            } else {
                this.errorMessages.category = '';
            }

            // Conditions validation (at least one condition should be true)
            if (!this.form.condition_1 && !this.form.condition_2 && !this.form.condition_3) {
                this.errorMessages.conditions = 'At least one condition must be selected.';
                isValid = false;
            } else {
                this.errorMessages.conditions = '';
            }

            return isValid;
        },
        searchData() {
            console.log('A:', this.searchTerm);
            if (this.searchTerm.trim() === '') {
                this.filteredPromoData = this.promoData; // If no input, show all data
            } else {
                const lowerCaseSearchTerm = this.searchTerm.toLowerCase();
                this.filteredPromoData = this.promoData.filter(item => {
                    // Check if item.promo is truthy (not null, undefined, false, etc.)
                    return item.promo && item.promo.toLowerCase().includes(lowerCaseSearchTerm);
                });
            }
        },
        applyDateFilter() {
            if (!this.startDate || !this.endDate) return;

            const start = new Date(this.startDate).getTime();
            const end = new Date(this.endDate).getTime();

            this.filteredPromoData = this.filteredPromoData.filter(item => {
                const itemStartDate = new Date(item.start_date).getTime();
                const itemEndDate = new Date(item.end_date).getTime();


                return itemStartDate >= start && itemEndDate <= end;
            });
            this.$bvToast.toast('Filtered applied succesfully', {
                title: 'Success',
                variant: 'success',
                toaster: 'b-toaster-top-right'
            })
            this.dateFilter = true;
            this.showModal = false;
        },
        removeDateFilter() {
            this.dateFilter = false;
            this.filteredPromoData = this.promoData;
            this.$bvToast.toast('Filtered removed succesfully', {
                title: 'Success',
                variant: 'success',
                toaster: 'b-toaster-top-right'
            })
        }

    },
    mounted() {
        this.getAllPromotions();
        this.getAllPartner();
        this.getAllCategory();
    }
};
</script>

<style scoped>
.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: rgb(116, 239, 114);
    animation: spin 1s linear infinite;
}

.filer__box {
    gap: 1.5rem;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>
