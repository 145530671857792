<template>
  <b-tr @click="getOrderDetails()">
    <b-td>{{getOrderID}}</b-td>
    <b-td>{{getServiceType}}</b-td>
    <b-td>{{getDate}}</b-td>
    <b-td>{{ getTime }}</b-td>
    <b-td>{{ getStatus }}</b-td>
  </b-tr>
</template>

<script>
export default {
  name: 'OrderLine',
  props: ['item'],
  components: {
  },
  data () {
    return {}
  },
  computed: {
    getOrderID: function () {
      return this.item.id
    },
    getOrderNumber: function () {
      return this.item.order_id
    },
    getServiceType: function () {
      return this.item.name
    },
    getStatus: function () {
      return this.item.status
    },
    getTime: function () {
      return this.item.time
    },
    getDate: function () {
      return this.item.date
    }
  },
  methods: {
    getOrderDetails: function () {
      
        this.$router.push({ name: 'SellOrders', params: { 'id': this.getOrderID } })
      
    }
  }
}
</script>

<style scoped>
</style>
