<template>
<div>
  <div class="row justify-content-between">
      <div @click="tabIndex = 0" v-bind:class="{'border rounded col p-3 m-2 font-weight-bold active-tab': tabIndex === 0, 'border rounded col p-3 m-2 shadow bg-white': tabIndex !== 0}">
        <div class="row no-gutters justify-content-center">
          <!-- <div class="col-auto"><img src="@/assets/imgs/reuse.png" class="image-icon mr-3" alt="Buy" /></div> -->
          <div class="col-auto d-flex mt-2">
            <div class="tab-label">Buyback Request</div>
            <div class="tab-count ml-3">{{repairCount}}</div>
          </div>
        </div>
      </div>
      <!-- <div @click="tabIndex = 1" v-bind:class="{'border rounded col p-3 m-2 font-weight-bold active-tab': tabIndex === 1, 'border rounded col p-3 m-2 shadow bg-white': tabIndex !== 1}">
        <div class="row no-gutters justify-content-center">
          <div class="col-auto"><img src="@/assets/imgs/repair.png" class="image-icon mr-1" alt="Repair" /></div>
          <div class="col-auto">
            <div class="tab-label">Repair</div>
            <div class="tab-count">{{repairCount}}</div>
          </div>
        </div>
      </div> -->
      <!-- <div @click="tabIndex = 2" v-bind:class="{'border rounded col p-3 m-2 font-weight-bold active-tab': tabIndex === 2, 'border rounded col p-3 m-2 shadow bg-white': tabIndex !== 2}">
        <div class="row no-gutters justify-content-center">
          <div class="col-auto"><img src="@/assets/imgs/reuse.png" class="image-icon mr-1" alt="Buy" /></div>
          <div class="col-auto">
            <div class="tab-label">Buy</div>
            <div class="tab-count">{{buyCount}}</div>
          </div>
        </div>
      </div> -->
      <!-- <div @click="tabIndex = 3" v-bind:class="{'border rounded col p-3 m-2 font-weight-bold active-tab': tabIndex === 3, 'border rounded col p-3 m-2 shadow bg-white': tabIndex !== 3}">
        <div class="row no-gutters justify-content-center">
          <div class="col-auto"><img src="@/assets/imgs/renew.png" class="image-icon mr-1" alt="Sell" /></div>
          <div class="col-auto">
            <div class="tab-label">Sell</div>
            <div class="tab-count">{{sellCount}}</div>
          </div>
        </div>
      </div> -->
  </div>
  <order-table v-if="filterOrders.length" :orderList="filterOrders"/>
</div>
</template>

<script>
import OrderTable from './OrderTable.vue'
export default {
  name: 'OrderList',
  props: ['orders'],
  components: {
    OrderTable
  },
  data () {
    return {
      orderList: false,
      tabIndex: 0
    }
  },
  watch: {
    orders (newdata) {
      this.orderList = newdata
    }
  },
  computed: {
    filterOrders: function () {
      if (!this.orderList) {
        return []
      }
      return this.orderList.filter((item) => {
        switch (this.tabIndex) {
          case 0: return item.id
        }
      })
    },
    repairCount: function () {
      if (!this.orderList) {
        return 0
      }
      let tempOrders = this.orderList
      return tempOrders.length
    },
    
  },
  methods: {},
  mounted () {}
}
</script>

<style scoped>
.tab-count{
  font-weight: bold;
  color: #28a745;
}
.tab-label{
  font-weight: bold;
  color: rgb(94, 94, 94);
}
.image-icon {
  max-width: 6vw;
  max-height: 6vh;
  border-radius: 50%;
}
.active-tab{
  border: 2px solid #28a745 !important;
}
</style>
