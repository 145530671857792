var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loader)?_c('div',{staticClass:"spinner-container"},[_c('div',{staticClass:"spinner"})]):_c('div',{staticClass:"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded",class:[_vm.color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']},[_c('div',{staticClass:"rounded-t mb-0 px-4 py-3 border-0"},[_c('div',{staticClass:"flex flex-wrap items-center"},[_c('div',{staticClass:"relative w-full px-4 max-w-full flex-grow flex-1"},[_c('h3',{staticClass:"font-semibold text-lg",class:[_vm.color === 'light' ? 'text-blueGray-700' : 'text-white']},[_vm._v(" GBB Details ")])])])]),_c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"card":"","vertical":""}},[_c('b-tab',{attrs:{"title":"Product Details","active":""}},[_c('b-card-text',[_c('div',{staticClass:"block w-full overflow-x-auto"},[_c('table',{staticClass:"items-center w-full bg-transparent border-collapse"},[_c('thead',[_c('tr',[_c('th',{staticClass:"px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
                          _vm.color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                        ]},[_vm._v(" Product Category ")]),_c('th',{staticClass:"px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
                          _vm.color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                        ]},[_vm._v(" Product Brand ")]),_c('th',{staticClass:"px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
                          _vm.color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                        ]},[_vm._v(" Product Name ")]),_c('th',{staticClass:"px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
                          _vm.color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                        ]},[_vm._v(" IMEI / Serial No ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[_vm._v(" "+_vm._s(_vm.data.product.category)+" ")]),_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[_vm._v(" "+_vm._s(_vm.data.product.brand)+" ")]),_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[_vm._v(" "+_vm._s(_vm.data.product.name)+" ")]),_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[_vm._v(" "+_vm._s(_vm.data.product.imei)+" ")])])])])])])],1),_c('b-tab',{staticStyle:{"padding":"0px !important","height":"10px !important"},attrs:{"title":"Partner"}},[_c('b-card-text',[_c('b-card',{staticStyle:{"padding":"0px !important","border":"none"},attrs:{"no-body":""}},[_c('b-tabs',{staticStyle:{"padding":"0px !important","border":"none !important"},attrs:{"align":"center"}},[_c('b-tab',{attrs:{"title":"Partner"}},[_c('b-card-text',[_c('div',{staticClass:"block w-full overflow-x-auto"},[_c('table',{staticClass:"items-center w-full bg-transparent border-collapse"},[_c('thead',[_c('tr',[_c('th',{staticClass:"px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
                                _vm.color === 'light'
                                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                              ]},[_vm._v(" Partner Name ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[_vm._v(" "+_vm._s(_vm.data.partner)+" ")])])])])])])],1)],1)],1)],1)],1),_c('b-tab',{staticStyle:{"padding":"0px !important","height":"10px !important"},attrs:{"title":"Customer"}},[_c('b-card-text',[_c('b-card',{staticStyle:{"padding":"0px !important","border":"none"},attrs:{"no-body":""}},[_c('b-tabs',{staticStyle:{"padding":"0px !important","border":"none !important"},attrs:{"align":"center"}},[_c('b-tab',{attrs:{"title":"Customer Details"}},[_c('b-card-text',[_c('div',{staticClass:"block w-full overflow-x-auto"},[_c('table',{staticClass:"items-center w-full bg-transparent border-collapse"},[_c('thead',[_c('tr',[_c('th',{staticClass:"px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
                                _vm.color === 'light'
                                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                              ]},[_vm._v(" Customer ID ")]),_c('th',{staticClass:"px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
                                _vm.color === 'light'
                                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                              ]},[_vm._v(" Customer Name ")]),_c('th',{staticClass:"px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
                                _vm.color === 'light'
                                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                              ]},[_vm._v(" Customer Email ")]),_c('th',{staticClass:"px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
                                _vm.color === 'light'
                                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                              ]},[_vm._v(" Customer Number ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[_vm._v(" "+_vm._s(_vm.data.customer_data.id)+" ")]),_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[_vm._v(" "+_vm._s(_vm.data.customer_data.name)+" ")]),_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[_vm._v(" "+_vm._s(_vm.data.customer_data.email)+" ")]),_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[_vm._v(" "+_vm._s(_vm.data.customer_data.number)+" ")])])])])])])],1)],1)],1)],1)],1),_c('b-tab',{attrs:{"title":"Condition"}},[_c('b-card-text',[_c('div',[_c('h5',{staticClass:"px-6 align-middle py-0 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
                    _vm.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]},[_vm._v("Device Condition")]),_c('div',{staticClass:"condition-html mt-0 ml-2",domProps:{"innerHTML":_vm._s(_vm.data.condition_description)}})])])],1),_c('b-tab',{attrs:{"title":"Product Images"}},[_c('b-card-text',[_c('div',{staticClass:"d-flex flex-column p-4 bg-dark container-image"},_vm._l((_vm.imageRows),function(row){return _c('b-row',{key:row.id,staticClass:"mb-3"},_vm._l((row),function(image,index){return _c('b-col',{key:index,staticClass:"d-flex justify-content-center align-items-center"},[(image)?_c('b-img',{staticClass:"img-thumb",attrs:{"thumbnail":"","fluid":"","src":'data:image/png;base64,' + image.file,"alt":'Image ' + (image.index + 1)},on:{"click":function($event){return _vm.showimg(image.name)}}}):_vm._e(),_c('b-modal',{attrs:{"id":image.name,"hide-footer":""}},[_c('div',{staticClass:"w-100 d-flex justify-content-center align-items-center"},[_c('img',{attrs:{"src":'data:image/png;base64,' + image.file,"width":"350px"}})])])],1)}),1)}),1)])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }